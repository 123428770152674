<template>
  <v-container>
<!-- Dialogs -->
    <UpdatePatientHistoryDialog ref="updatePatientHistoryDialog" />

<!-- Heading -->
    <v-row>
      <v-col>
        <h1>Patient Chart</h1>
      </v-col>
      <v-col class="d-flex justify-end" v-if="hasAccess([Roles.ADMIN, Roles.BASIC, Roles.CLINIC_STAFF])">
        <v-btn @click="routeToPatientProfile" small color="primary" class="mt-4">Patient Profile</v-btn>
      </v-col>
    </v-row>

<!--  Patient Demographics -->
    <h2 class="mt-6">Patient Demographics</h2>
    <div class="mt-4">
      <v-row dense>
        <v-col>
          <div class="demographic-header">Name:</div>
          <div>{{patient.full_name}}</div>
        </v-col>
        <v-col>
          <div class="demographic-header">DOB:</div>
          <div>{{patient.pretty_dob}}</div>
        </v-col>
        <v-col>
          <div class="demographic-header">Phone:</div>
          <div>{{formatPhone(patient.main_phone)}}</div>
        </v-col>
        <v-col>
          <div class="demographic-header">Email:</div>
          <div>{{patient.email}}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="demographic-header">Address:</div>
          <div>{{patient.full_address}}</div>
        </v-col>
      </v-row>
    </div>

    <h2 class="mt-12">Medical Summary</h2>
<!--  Alerts -->
    <div class="mt-4">
      <Alerts @alertChange="getPatient"/>
    </div>

<!--  Encounters -->
    <div class="mt-14">
      <Encounters ref="encounters" @encounterChange="getPatient"/>
    </div>

<!--  Documents/Files -->
    <div class="mt-14">
      <Files v-if="patient.id" :case-id="patient.case.id" :files="documents" @filesChange="getPatient"/>
    </div>

<!--  Referrals  -->
    <div class="mt-14">
      <PatientReferrals @referralChange="getPatient"/>
    </div>

<!--  Patient Notes  -->
    <div class="mt-14">
      <h3 class="mb-3">Patient Notes</h3>
      <v-textarea
        v-model="patientNote"
        rows="4"
        filled
        dense
        placeholder="Add a note..."
      />
      <v-col class="d-flex justify-end pt-0" cols="12">
        <v-btn
          class=""
          @click="createPatientNote"
          small
          color="primary"
        >Add Note</v-btn>
      </v-col>
    </div>

    <!-- Patient History -->
    <div class="mt-14">
      <h3>Patient History</h3>
      <PatientLogs ref="patientLogs" />
    </div>
  </v-container>
</template>
<script>

import { mapGetters } from 'vuex'
import PhoneMixin from '@/mixins/PhoneMixin'
import { Roles } from '@/js/Roles'
import Files from '@/components/Case/Files.vue'
import PatientReferrals from '@/components/Provider/PatientReferrals.vue'
import Alerts from '@/components/Provider/Alerts.vue'
import Encounters from '@/components/Provider/Encounters.vue'
import { PatientLogActions } from '@/js/Provider/AppointmentEnums'
import event, { Events } from '@/event'
import UpdatePatientHistoryDialog from '@/components/Case/Dialogs/UpdatePatientHistoryDialog.vue'
import PatientLogs from '@/components/Provider/PatientLogs.vue'

export default {
  name: 'PatientChartEditView',
  components: { Encounters, PatientReferrals, Files, Alerts, UpdatePatientHistoryDialog, PatientLogs },
  mixins: [PhoneMixin],
  data: () => ({
    Roles,
    patientNote: ''
  }),
  computed: {
    ...mapGetters({
      patient: 'ProviderPatient/patientDemographics',
      url: 'Organization/apiUrl',
      documents: 'ProviderPatient/patientDocuments',
      hasAccess: 'User/hasAccess'
    })
  },
  mounted () {
    this.getPatient()
  },
  methods: {
    async getPatient () {
      await this.$store.dispatch('ProviderPatient/getCurrentPatient', this.$route.params.patientId)
    },
    async createPatientNote () {
      try {
        await window.axios.post(
          this.$store.getters['Organization/apiUrl'] + '/provider/patients/' + this.patient.id + '/logs', {
            action: PatientLogActions.PATIENT_NOTE,
            note: this.patientNote,
            appointment_id: null
          })
        await this.getPatient()
        this.patientNote = ''
        event.emit(Events.SUCCESS, 'Patient note created successfully')
      } catch (error) {
        event.emit(Events.ERROR, error.response.data.payload.message, 2000)
      }
    },
    routeToPatientProfile () {
      this.$router.push({ name: 'CaseView', params: { caseId: this.patient.case.id.toString() } })
    }
  }
}
</script>
<style scoped>
.demographic-header {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 3px;
}
</style>
