<template>
  <v-dialog @click:outside="cancel" :value="dialog" @input="$emit('input', $event)" max-width="600" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title>Update Appointment</v-card-title>
      <v-card-text>
        <AppointmentForm v-if="appointment.id" :passedAppointment="appointment" ref="appointmentForm" @updated="reassignAppointment"/>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn @click="cancel">Cancel</v-btn>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-btn depressed color="primary" @click="updateAppointment">Save</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import event, { Events } from '@/event'
import AppointmentForm from '@/components/Case/Forms/AppointmentForm.vue'

export default {
  name: 'UpdateAppointmentDialog',
  components: { AppointmentForm },
  mixins: [],
  data: () => ({
    dialog: false,
    appointment: {},
    caseId: ''
  }),
  computed: {},
  methods: {
    open (id, appointment) {
      this.caseId = id
      this.appointment = appointment
      this.dialog = true
    },
    cancel () {
      this.dialog = false
      this.$emit('reload')
      this.appointment = {}
      this.$refs.appointmentForm.reset()
    },
    reassignAppointment (value) {
      this.appointment = value
    },
    async updateAppointment () {
      this.$refs.appointmentForm.validate()
      if (!this.$refs.appointmentForm.formValid || this.$refs.appointmentForm.dateValid === 'Required') {
        return
      }

      const response = await window.axios.put(this.$store.getters['Organization/apiUrl'] + '/case/' + this.caseId + '/appointments/' + this.appointment.id, {
        appointment: this.appointment
      })

      event.emit(Events.SUCCESS, 'Appointment updated successfully')
      this.$emit('reload', response.data.payload)
      this.cancel()
    },
    reset () {
      this.$refs.appointmentForm.reset()
      this.$emit('reload')
      this.dialog = false
    }
  }
}
</script>
