
export const EncounterTypes = {
  TELEPHONE_ENCOUNTER: 'telephone_encounter',
  PROVIDER_ENCOUNTER: 'provider_encounter'
}

export const EncounterOptions = [
  EncounterTypes.TELEPHONE_ENCOUNTER,
  EncounterTypes.PROVIDER_ENCOUNTER
]

export const ProviderOption = {
  ALL_LOCATION_PROVIDERS: { provider_name: 'All Providers At Location', id: 'all_location_providers' },
  ALL_PATIENT_PROVIDERS: { provider_name: 'All Providers Assigned To Patient', id: 'all_patient_providers' }
}

export const ProviderOptions = [
  ProviderOption.ALL_LOCATION_PROVIDERS,
  ProviderOption.ALL_PATIENT_PROVIDERS
]
