<template>
  <v-form ref="form" v-model="formValid">
    <v-row dense>
      <v-col>
        <v-select
          v-model="appointment.location_id"
          label="Location"
          :items="locations"
          @input="appointment.provider_id = null"
          @change="updateProviderOptions"
          item-value="id"
          item-text="name"
          filled
          placeholder="Location"
          :rules="[rules.required]"
          :disabled="patientHasHold"
        />
      </v-col>
      <v-col>
        <v-select
          :items="VisitTypeOptions"
          label="Visit Type"
          v-model="appointment.visit_type"
          @change="updateDurationInput"
          item-value="value"
          item-text="text"
          :rules="[rules.required]"
          placeholder="Visit Type"
          filled
          :disabled="patientHasHold"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-select
            :items="updateVisitStatusOptions()"
            label="Status"
            v-model="appointment.status"
            :rules="[rules.required]"
            item-value="value"
            item-text="text"
            placeholder="Status"
            filled
            @input="appointment.status_change_note = null"
        />
      </v-col>
      <v-col>
        <v-select
            v-model="appointment.provider_id"
            label="Provider"
            :items="providerOptions"
            :readonly="!appointment.location_id"
            :hint="Boolean(appointment.location_id) ? '' : 'Select a location first'"
            item-value="id"
            item-text="pretty_provider"
            :rules="[rules.required]"
            placeholder="Provider"
            filled
            :disabled="patientHasHold"
        />
      </v-col>
    </v-row>
    <v-row v-if="(appointment.status === VisitStatuses.CANCEL ||
                  appointment.status === VisitStatuses.N_S ||
                  appointment.status === VisitStatuses.R_S)"
           dense
    >
      <v-textarea
        v-model="appointment.status_change_note"
        rows="2"
        filled
        dense
        height="80px"
        label="Note about the current status change..."
        :rules="[rules.required]"
      />
    </v-row>
    <v-row dense>
      <v-col cols="4">
        <date-picker
          ref="datepicker"
          :date="appointment.date"
          :default-to-today="false"
          @date-change="setDate"
          :dateValid="dateValid"
          placeholder="Date"
          @blur="validateDate"
          :disabled="patientHasHold"
        />
      </v-col>
      <v-col cols="4">
        <time-picker
          ref="timepicker"
          :time="appointment.time"
          placeholder="Time"
          :timeValid="timeValid"
          @blur="validateTime"
          @time-change="setTime"
          :disabled="patientHasHold"
        />
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="appointment.duration"
          label="Duration"
          :items=DurationOptions
          placeholder="Duration"
          item-value="value"
          item-text="text"
          hint="Duration set by visit type"
          :readonly="appointment.visit_type !== VisitTypes.PROCEDURES"
          filled
          :disabled="patientHasHold"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col class="pb-0">
        <v-textarea
          v-model="appointment.contraindications"
          rows="2"
          filled
          dense
          placeholder="Contraindications..."
          height="80px"
          label="Contraindications"
          :disabled="patientHasHold"
        />
      </v-col>
    </v-row>
  </v-form>
</template>
<script>

import DatePicker from '@/components/PatientIntake/DatePicker.vue'
import TimePicker from '@/components/TimePicker.vue'
import {
  DurationOptions,
  VisitTypes,
  VisitStatusOptions,
  VisitTypeOptions,
  VisitStatuses
} from '@/js/Provider/AppointmentEnums'
import { mapGetters } from 'vuex'
import RulesMixin from '@/mixins/RulesMixin'
import structuredClone from '@ungap/structured-clone'

export default {
  name: 'PatientAppointmentForm',
  components: {
    TimePicker,
    DatePicker
  },
  mixins: [RulesMixin],
  props: {
    appointment: {
      type: Object,
      required: false
    },
    patientHasHold: {
      type: Boolean,
      required: true
    },
    appointmentIsUpdate: {
      type: Boolean,
      required: false
    }
  },
  data: () => ({
    VisitTypes,
    VisitStatuses,
    DurationOptions,
    VisitTypeOptions,
    VisitStatusOptions,
    locationOptions: [],
    providerOptions: [],
    formValid: true,
    dateValid: true,
    timeValid: true,
    appointmentComplete: false
  }),
  computed: {
    ...mapGetters({
      locations: 'Organization/locations',
      providers: 'Organization/providers'
    })
  },
  methods: {
    updateProviderOptions () {
      this.providerOptions = []
      this.providerOptions = this.providersList.filter((provider) => provider.locations
        .some(location => location.id === this.appointment.location_id))
    },
    setDate (value) {
      this.appointment.date = value
    },
    setTime (value) {
      this.appointment.time = value.time + ' ' + value.day
    },
    validateDate () {
      this.dateValid = true
      if (!this.appointment.date) {
        this.dateValid = 'Required'
      }
    },
    validateTime () {
      this.timeValid = true
      if (!this.appointment.time) {
        this.timeValid = 'Required'
      }
    },
    updateDurationInput () {
      const selectedVisitType = VisitTypeOptions.find(type => type.value === this.appointment.visit_type)
      this.appointment.duration = selectedVisitType.duration
    },
    updateVisitStatusOptions () {
      if (!this.appointmentIsUpdate) {
        return VisitStatusOptions.filter((status) => status.value === VisitStatuses.PENDING ||
          status.value === VisitStatuses.CHECK_IN ||
          status.value === VisitStatuses.CHECK_OUT ||
          status.value === VisitStatuses.STAFF_CHANGE
        )
      }

      if (this.patientHasHold) {
        const originalSelectedStatusOption = this.VisitStatusOptions.find((status) => status.value === this.appointment.status)
        if (originalSelectedStatusOption) {
          return [originalSelectedStatusOption, { text: VisitStatuses.CANCEL, value: VisitStatuses.CANCEL }]
        }
      }

      return VisitStatusOptions
    },
    validateForm () {
      this.$refs.form.validate()
      this.validateDate()
      this.validateTime()

      this.appointmentComplete = this.formValid && this.timeValid !== 'Required' && this.dateValid !== 'Required'
    },
    reset () {
      this.dateValid = true
      this.timeValid = true
      this.formValid = true
      this.$refs.form.reset()
      this.$nextTick(() => {
        this.$refs.datepicker.clearInput()
        this.$refs.datepicker.checkDate()
        this.$refs.timepicker.clearInput()
        this.$refs.timepicker.checkTime()
      })
    }
  },
  watch: {
    appointment: function () {
      this.updateProviderOptions()
    }
  },
  async mounted () {
    await this.$store.dispatch('Organization/getProviders')
    this.providersList = structuredClone(this.providers)
    this.updateProviderOptions()
  }
}
</script>

<style scoped>

</style>
