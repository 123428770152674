<template>
  <v-dialog
    @click:outside="cancel"
    :value="dialog"
    max-width="800"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
<!--  Dialogs -->
    <AppointmentNoteDialog ref="appointmentNoteDialog"/>
<!--  Appointment  -->
    <v-card ref="card" v-if="patient.id">
      <v-card-title class="primary white--text">Appointment For {{ patient.full_name }}</v-card-title>
      <v-card-text class="pa-0">
        <div class="grey lighten-3 text-black px-6 py-4">
<!--  Patient Section    -->
          <div class="d-flex justify-space-between align-center">
            <h3>Patient Information</h3>
          </div>
          <div class="mt-4">
            <v-row>
              <v-col sm="2" cols="12">
                <div class="demographic-header">Name:</div>
                <div>{{patient.full_name}}</div>
              </v-col>
              <v-col sm="2" cols="12">
                <div class="demographic-header">DOB:</div>
                <div>{{formatCarbonDateNoSetTime(patient.dob)}}</div>
              </v-col>
              <v-col sm="2" cols="12">
                <div class="demographic-header">Phone:</div>
                <div>{{formatPhone(patient.main_phone)}}</div>
              </v-col>
              <v-col sm="2" cols="12">
                <div class="demographic-header">Email:</div>
                <div>{{ patient.email }}</div>
              </v-col>
              <v-col sm="4" cols="12">
                <div class="demographic-header">Address:</div>
                <div>{{ patient.full_address }}</div>
              </v-col>
            </v-row>
          </div>
        </div>
<!--  Appointment Form -->
        <div class="px-6">
          <v-row class="my-2">
            <v-col>
              <h3>Appointment</h3>
            </v-col>
            <v-col class="d-flex justify-end">
              <v-btn small class="primary" @click="routeToProviderEncounter">View Encounter</v-btn>
            </v-col>
          </v-row>
          <p
            v-if="patientHasHold"
            class='red--text'
          >
            Patient currently has a hold.  Only the appointment status can be changed to 'Cancel'.
          </p>
          <PatientAppointmentForm
            ref="appointmentForm"
            :appointment="appointment"
            :patient-has-hold="patientHasHold"
            :appointment-is-update="true"
          />
          <!--  Alerts -->
          <h3 class="mt-4 mb-3">Alerts</h3>
          <v-alert v-if="alerts.length === 0" dense text type="success">No Pending Alerts</v-alert>
          <div v-for="(alert, index ) in alerts" :key="index">
            <v-alert dense text type="error">{{alert.alert}}</v-alert>
          </div>
<!--  Referrals -->
<!--  TODO create a view Referral Option (no update or cancel)-->
          <PatientReferrals class="mt-14" @referralChange="getPatient"/>
          <!--  Patient Notes-->
          <div class="mt-6">
            <h3 class="mb-3">Patient Notes</h3>
            <v-textarea
              v-model="patientNote"
              rows="4"
              filled
              dense
              placeholder="Note..."
            />
            <v-col class="d-flex justify-end pt-0" cols="12">
              <v-btn
                class=""
                @click="createPatientNote"
                small
                color="primary"
                :disabled="patientNote === ''"
              >Add Note</v-btn>
            </v-col>
            <v-timeline
              v-if="patientNotesLogs.length > 0"
              align-top
              dense
            >
              <div v-for="(log, index ) in patientNotesLogs" :key="index">
                <v-timeline-item small>
                  <v-row class="pt-1">
                    <v-col cols="6">{{ log.note }}</v-col>
                    <v-col>
                      <strong>{{ log.user.name }}</strong>
                      <div class="text-caption">
                        {{ formatCarbonDate(log.created_at) }}
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </div>
            </v-timeline>
          </div>
<!-- Appointment Logs -->
          <div class="mt-14">
            <h3>Appointment History</h3>
            <v-data-table
              :items="appointmentLogs"
              :headers="appointmentLogHeaders"
              :disable-sort="true"
            >
              <template #item.createdAt="{ item }">
                {{ formatCarbonDate(item.created_at) }}
              </template>
              <template #item.userName="{ item }">
                {{ item.user.name }}
              </template>
              <template #item.action="{ item }">
                {{ item.action }}
              </template>
              <template #item.appointmentNote="{ item }">
                <span @click="$refs.appointmentNoteDialog.open(item.note)">{{ formatAppointmentNoteForDataTable(item.note) }}</span>
              </template>
            </v-data-table>
            </div>
        </div>
      </v-card-text>
<!--  Save & Cancel -->
      <v-card-actions>
        <v-row class="mb-4 mt-2">
          <v-col class="d-flex justify-end">
            <v-btn class="primary" @click="saveAppointment">Save</v-btn>
          </v-col>
          <v-col>
            <v-btn @click="cancel">Cancel</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import DateMixin from '@/mixins/DateMixin'
import PhoneMixin from '@/mixins/PhoneMixin'
import { formatCarbonDate, formatCarbonDateNoSetTime } from '@/js/PatientIntake/functions'
import PatientAppointmentForm from '@/components/PatientAppointmentForm.vue'
import PatientReferrals from '@/components/Provider/PatientReferrals.vue'
import { mapGetters } from 'vuex'
import event, { Events } from '@/event'
import { PatientLogActions } from '@/js/Provider/AppointmentEnums'
import AppointmentNoteDialog from '@/components/ProviderPatients/Dialogs/AppointmentNoteDialog.vue'
import { Roles } from '@/js/Roles'
import { parseDate, dateTimesOverlap, convertToPrettyDate } from '@/js/Provider/ProviderScheduleDateTimeFunctions'

export default {
  name: 'UpdateAppointmentProviderPatientDialog',
  components: { PatientAppointmentForm, PatientReferrals, AppointmentNoteDialog },
  mixins: [DateMixin, PhoneMixin],
  props: ['calendarEvents'],
  data: () => ({
    parseDate,
    dateTimesOverlap,
    convertToPrettyDate,
    Roles,
    PatientLogActions,
    dialog: false,
    currentPatientId: null,
    currentAppointmentId: null,
    patientNote: '',
    patientNotesLogs: [],
    appointmentLogHeaders: [
      { text: 'Action Date', value: 'createdAt' },
      { text: 'Author', value: 'userName' },
      { text: 'Action', value: 'action' },
      { text: 'Note', value: 'appointmentNote' }
    ],
    appointmentLogs: [],
    appointment: {
      location_id: null,
      visit_type: '',
      status: '',
      provider_id: null,
      date: '',
      time: '',
      contraindications: '',
      duration: '',
      status_change_note: null
    },
    dateTimeIsAvailable: true,
    dateTimeConflictMessage: ''
  }),
  computed: {
    ...mapGetters({
      patient: 'ProviderPatient/patientDemographics',
      referrals: 'ProviderPatient/patientReferrals',
      alerts: 'ProviderPatient/patientAlerts',
      logs: 'ProviderPatient/patientLogs'
    }),
    patientHasHold () {
      return this.patient.alerts.length > 0
    }
  },
  methods: {
    formatCarbonDate,
    formatCarbonDateNoSetTime,
    async open (item) {
      if (!item.event.fullAppointmentData) {
        return
      }
      this.currentPatientId = item.event.fullAppointmentData.patient_id
      this.currentAppointmentId = item.event.fullAppointmentData.id
      await this.getPatient()
      this.dialog = true
    },
    cancel () {
      this.dialog = false
      this.patientNote = ''
      this.patientNotesLogs = []
      this.currentPatientId = null
      this.resetAppointmentForm()
      this.$refs.card.$el.scrollIntoView()
      this.$emit('update-appointment-dialog-closing')
    },
    resetAppointmentForm () {
      this.appointment = {
        location_id: null,
        visit_type: '',
        status: '',
        provider_id: null,
        date: '',
        time: '',
        contraindications: '',
        duration: '',
        status_change_note: null
      }
      this.dateTimeIsAvailable = true
      this.dateTimeConflictMessage = ''
      this.$refs.appointmentForm.reset()
    },
    async getPatient () {
      await this.$store.dispatch('ProviderPatient/getCurrentPatient', this.currentPatientId)
      this.updateAppointmentForm()
      this.filterPatientNotes()
      this.updateAppointmentLogs()
    },
    updateAppointmentForm () {
      this.appointment = this.patient.appointments.find((appointment) => appointment.id === this.currentAppointmentId)
    },
    filterPatientNotes () {
      this.patientNotesLogs = this.logs.filter(log => log.action === PatientLogActions.PATIENT_NOTE)
    },
    async createPatientNote () {
      try {
        await window.axios.post(
          this.$store.getters['Organization/apiUrl'] + '/provider/patients/' + this.currentPatientId + '/logs', {
            action: PatientLogActions.PATIENT_NOTE,
            note: this.patientNote,
            appointment_id: null
          })
        await this.getPatient()
        this.patientNote = ''
        event.emit(Events.SUCCESS, 'Patient note created successfully')
      } catch (error) {
        event.emit(Events.ERROR, error.response.data.payload.message, 2000)
      }
    },
    updateAppointmentLogs () {
      this.appointmentLogs = this.patient.logs.filter((log) => log.appointment_id === this.currentAppointmentId)
    },
    formatAppointmentNoteForDataTable (note) {
      if (!note) {
        return '---'
      }

      if (note.length > 80) {
        return note.substring(0, 80) + '...'
      } else {
        return note
      }
    },
    validateDateTimeAvailability () {
      this.dateTimeIsAvailable = true
      this.dateTimeConflictMessage = ''
      const activeAppointments = this.calendarEvents.filter((event) =>
        event.isTimeBlock === false &&
        event.status !== 'Cancel' &&
        event.status !== 'Reschedule' &&
        event.status !== 'No Show' &&
        event.fullAppointmentData.id !== this.appointment.id &&
        event.fullAppointmentData.provider_id === this.appointment.provider_id
      )
      const currentAppointmentDateTimeStart = this.parseDate(this.appointment.date, this.appointment.time)
      const currentAppointmentDateTimeEnd = this.parseDate(this.appointment.date, this.appointment.time).setMinutes(currentAppointmentDateTimeStart.getMinutes() + +this.appointment.duration)

      const currentAppointmentDateRange = [currentAppointmentDateTimeStart, currentAppointmentDateTimeEnd]
      activeAppointments.forEach((appointment) => {
        const appointmentDateRange = [appointment.start, appointment.end]
        if (this.dateTimesOverlap(appointmentDateRange, currentAppointmentDateRange)) {
          this.dateTimeIsAvailable = false
          this.dateTimeConflictMessage = 'appointment'
        }
      })

      const providerTimeBlocks = this.calendarEvents.filter((event) =>
        event.isTimeBlock === true &&
        event.fullTimeBlockData.provider_id === this.appointment.provider_id
      )

      providerTimeBlocks.forEach((timeBlock) => {
        const timeBlockDateRange = [timeBlock.start, timeBlock.end]
        if (this.dateTimesOverlap(timeBlockDateRange, currentAppointmentDateRange)) {
          this.dateTimeIsAvailable = false
          this.dateTimeConflictMessage = 'time block'
        }
      })
    },
    async saveAppointment () {
      this.$refs.appointmentForm.validateForm()

      if (!this.$refs.appointmentForm.appointmentComplete) {
        return
      }

      if (this.patientHasHold && this.appointment.status !== 'Cancel') {
        event.emit(Events.ERROR, `Error: ${this.patient.full_name} has a hold. This appointment status can only be set to 'Cancel'.`, 2000)
        return
      }

      this.validateDateTimeAvailability()

      if (this.dateTimeIsAvailable === false) {
        event.emit(Events.ERROR, `This provider already has a scheduled ${this.dateTimeConflictMessage} on ${this.convertToPrettyDate(this.appointment.date)} at ${this.appointment.time} `, 2000)
        return
      }

      try {
        await window.axios.put(this.$store.getters['Organization/apiUrl'] +
          '/provider/patients/' +
          this.patient.id +
          '/appointments/' +
          this.currentAppointmentId, {
          appointment: this.appointment
        })
        event.emit(Events.SUCCESS, 'Appointment updated successfully')
        await this.$store.dispatch('User/updateUserTasks')
        this.resetAppointmentForm()
        this.cancel()
      } catch (error) {
        event.emit(Events.ERROR, error.response.data.payload.message, 2000)
      }
    },
    routeToProviderEncounter () {
      this.$router.push({
        name: 'ProviderEncounterView',
        params: {
          patientId: this.patient.id.toString(),
          encounterId: this.appointment.provider_encounter.id
        }
      })
    }
  }
}
</script>
