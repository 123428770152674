<template>
  <v-container>
    <UpdateReferralOutDialog ref="updateReferralDialog" @reload="getPolarisReferrals"/>
    <h1>Polaris Referrals</h1>
    <div v-if="referrals === 0" class="mt-2">
      <p>There are currently no polaris referrals matching the search or location</p>
      <v-btn depressed color="primary" href="/cases/active">
        All Cases
      </v-btn>
    </div>

    <div class="mt-4">
      <v-data-table :items="referrals"
                    :headers="headers"
                    @click:row="goToCase($event.case_id)"
                    :server-items-length="totalItems"
                    :page.sync="requestParams.page"
                    :sort-by.sync="requestParams.orderBy"
                    :sort-desc.sync="requestParams.sortDesc"
                    :items-per-page.sync="requestParams.itemsPerPage"
        >
        <template v-slot:top>
          <search-bar v-on:child-method="updateSearchInput"/>
        </template>
        <template #item.status="{item}">
          {{capitalizeFirstWord(item.status)}}
        </template>
        <template #item.patient="{ item }">
          <div class="d-flex">
            <div class="mt-1 ml-1">{{ item.case.patient.full_name }}</div>
            <v-tooltip right color="error" v-if="item.case.patient.alerts.length > 0">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="ml-2" color="error">fas fa-exclamation-triangle</v-icon>
              </template>
              <ul v-for="(alert, index) in item.case.patient.alerts" :key="index">
                <li>{{alert.alert}}</li>
              </ul>
            </v-tooltip>
          </div>
        </template>
        <template #item.service="{ item }">
          {{ item.service_location.name}}
        </template>
        <template #item.actions="{ item }">
          <v-menu>
            <template #activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>fas fa-ellipsis-h</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="$refs.updateReferralDialog.open(item)">
                <v-list-item-title>Update Referral</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>
<script>
import AddressMixin from '../../mixins/AddressMixin'
import DateMixin from '../../mixins/DateMixin'
import SearchBar from '@/components/FormFields/SearchBar'
import event, { Events } from '@/event'
import { capitalizeFirstWord } from '@/js/functions'
import UpdateReferralOutDialog from '@/components/Case/Dialogs/ReferralsOut/UpdateReferralOutDialog.vue'
import { formatCarbonDateNoSetTime } from '@/js/PatientIntake/functions'

export default {
  name: 'Referrals',
  components: { SearchBar, UpdateReferralOutDialog },
  mixins: [AddressMixin, DateMixin],
  data: () => ({
    headers: [
      { text: 'Case ID', value: 'case_id' },
      { text: 'Status', value: 'status' },
      { text: 'Patient Name', value: 'patient' },
      { text: 'Service Location', value: 'service' },
      { text: 'Treatment', value: 'treatment' },
      { text: 'Date Sent', value: 'date_sent' },
      { text: 'Quick Actions', value: 'actions', sortable: false }
    ],
    requestParams: {
      page: 1,
      itemsPerPage: 10,
      orderBy: 'id',
      sortDesc: false,
      debouncedSearch: null
    },
    referrals: [],
    totalItems: 0
  }),
  mounted () {
    this.getPolarisReferrals()
  },
  methods: {
    capitalizeFirstWord,
    updateSearchInput (valueFromChild) {
      this.requestParams.debouncedSearch = valueFromChild
    },
    goToCase (caseId) {
      this.$router.push({ name: 'CaseView', params: { caseId: caseId.toString() } })
    },
    getPolarisReferrals () {
      this.$store.commit('loading', true)

      let searchParams
      if (this.requestParams.debouncedSearch) {
        searchParams = this.requestParams.debouncedSearch.toString().toLowerCase()
      }

      event.emit(Events.LOADING, true)

      this.$store
        .dispatch('Organization/getPolarisReferrals', { search: searchParams })
        .then((response) => {
          this.referrals = response.referrals
          this.formatReferralDates()
          this.totalItems = response.total
          this.$store.commit('loading', false)
        }).finally(() => {
          event.emit(Events.LOADING, false)
        })
    },
    formatReferralDates () {
      this.referrals.map(referral => {
        referral.date_sent = formatCarbonDateNoSetTime(referral.date_sent)
        if (referral.appointment_date) {
          referral.appointment_date = formatCarbonDateNoSetTime(referral.appointment_date)
        }
      })
    }
  },
  watch: {
    requestParams: {
      handler: function () {
        this.getPolarisReferrals()
      },
      deep: true
    }
  }
}
</script>
<style scoped>
.v-tooltip__content {
  opacity: 1 !important;
}
</style>
