var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CreateProviderPatientAppointmentDialog',{ref:"createAppointmentDialog",attrs:{"calendar-events":_vm.calendarEvents}}),_c('v-dialog',{attrs:{"value":_vm.dialog,"max-width":"800","fullscreen":_vm.$vuetify.breakpoint.xsOnly},on:{"click:outside":_vm.cancel}},[_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_vm._v("Patient Search")]),_c('v-card-text',[_c('v-data-table',{staticStyle:{"min-height":"631px"},attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.patients,"server-items-length":_vm.totalPatientsCount,"footer-props":{'items-per-page-options': [25, 50, 100]}},on:{"update:options":_vm.getPatients},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"4"}},[_c('search-bar',{ref:"searchBar",attrs:{"hint":'When searching DOB use format: YYYY-MM-DD'},on:{"child-method":_vm.updateSearchInput}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{ref:"statusSelect",staticClass:"ml-3 CaseStatus",attrs:{"items":_vm.caseStatusOptions,"item-text":"text","item-value":"value","label":"Case Status","dense":"","filled":"","hide-details":"","clearable":""},on:{"change":_vm.getPatients,"click:clear":_vm.resetStatusSelect},model:{value:(_vm.selectedCaseStatus),callback:function ($$v) {_vm.selectedCaseStatus=$$v},expression:"selectedCaseStatus"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{ref:"locationSelect",staticClass:"ml-3 CaseStatus",attrs:{"items":_vm.updatedLocations,"item-text":"name","item-value":"id","label":"Facility","dense":"","filled":"","hide-details":"","clearable":""},on:{"input":_vm.getPatients,"click:clear":_vm.resetLocationSelect},model:{value:(_vm.selectedLocation),callback:function ($$v) {_vm.selectedLocation=$$v},expression:"selectedLocation"}})],1)],1)]},proxy:true},{key:"item.alerts",fn:function(ref){
var item = ref.item;
return [(item.alerts.length > 0)?_c('v-tooltip',{attrs:{"right":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v("fas fa-exclamation-triangle")])]}}],null,true)},_vm._l((item.alerts),function(alert,index){return _c('ul',{key:index},[_c('li',[_vm._v(_vm._s(alert.alert))])])}),0):_vm._e()]}},{key:"item.dob",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCarbonDateNoSetTime(item.dob))+" ")]}},{key:"item.main_phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPhone(item.main_phone))+" ")]}},{key:"item.case.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.capitalizeFirstWord(item.case.status))+" ")]}},{key:"item.case.location.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.case.location.name)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fas fa-ellipsis-h")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.routeToPatientChart(item)}}},[_c('v-list-item-title',[_vm._v("Patient Chart")])],1),(_vm.hasAccess([_vm.Roles.ADMIN, _vm.Roles.BASIC, _vm.Roles.CLINIC_STAFF]))?_c('v-list-item',{on:{"click":function($event){return _vm.routeToPatientProfile(item)}}},[_c('v-list-item-title',[_vm._v("Patient Profile")])],1):_vm._e(),_c('v-tooltip',{attrs:{"disabled":item.alerts.length === 0,"left":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(_vm.hasAccess([_vm.Roles.ADMIN, _vm.Roles.BASIC, _vm.Roles.CLINIC_STAFF, _vm.Roles.PROVIDER]))?_c('v-list-item',{attrs:{"disabled":item.alerts.length > 0},on:{"click":function($event){return _vm.$refs.createAppointmentDialog.open(item)}}},[_c('v-list-item-title',[_vm._v("Create Appointment")])],1):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.full_name + ' currently has a hold'))])])],1)],1)]}}])})],1),_c('v-card-actions',{staticClass:"d-flex"},[_c('v-btn',{on:{"click":_vm.cancel}},[_vm._v("Cancel")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }