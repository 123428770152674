import { email, phone, ssn } from '../library/Validators'

export default {
  data: () => ({
    rules: {
      required: v => !!v || 'Required',
      optional: v => (!!v || !v) || '',
      email: value => email(value) || 'Must be a valid E-Mail',
      phone: v => phone(v) || 'Phone must be valid',
      ssn: v => ssn(v) || 'Must be a valid ssn number',
      zip: v => /^\d{5}(-\d{4})?$/.test(v) || 'Zip Code must be valid',
      optionalPhone: v => (!v || /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v)) || 'Phone must be valid',
      optionalEmail: v => (!v || email(v)) || 'Must be a valid E-mail',
      optionalSSN: v => (!v || ssn(v)) || 'Must be a valid SSN',
      requiredMultiSelect: v => v.length !== 0 || 'Required'
    }
  })
}
