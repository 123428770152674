var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('CreateAndUpdateTimeBlocksDialog',{ref:"createAndUpdateTimeBlocksDialog",attrs:{"calendar-events":_vm.calendarEvents,"selected-provider-id":_vm.selectedProvider,"selected-location-id":_vm.locationId},on:{"fetch-time-blocks":_vm.fetchTimeBlocks}}),_c('AppointmentNoteDialog',{ref:"appointmentNoteDialog"}),_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_vm._v("Manage Provider Time Blocks")]),_c('v-card-text',{staticClass:"pa-5"},[_c('v-select',{attrs:{"label":"Select a provider","items":_vm.locationProviders,"item-value":"id","item-text":"pretty_provider","placeholder":"Provider","filled":""},on:{"input":_vm.updateProviderTimeBlocks},model:{value:(_vm.selectedProvider),callback:function ($$v) {_vm.selectedProvider=$$v},expression:"selectedProvider"}}),_c('div',{staticClass:"my-3 d-flex justify-end"},[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.$refs.createAndUpdateTimeBlocksDialog.open()}}},[_vm._v(" Add Time Block ")])],1),_c('h3',[_vm._v("Active Time Blocks")]),_c('v-data-table',{staticClass:"mt-2",attrs:{"items":_vm.selectedProviderTimeBlocks,"headers":_vm.timeBlockHeaders,"disable-sort":true},scopedSlots:_vm._u([{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCarbonDateNoSetTime(item.start_date))+" ")]}},{key:"item.start_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.start_time)+" ")]}},{key:"item.end_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.end_time)+" ")]}},{key:"item.is_ongoing",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.end_date ? 'Yes' : 'No')+" ")]}},{key:"item.end_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.end_date ? _vm.formatCarbonDateNoSetTime(item.end_date) : '---')+" ")]}},{key:"item.days_of_week",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.end_date ? _vm.formatDaysOfWeek(item.days_of_week) : '---')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fas fa-ellipsis-h")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$refs.createAndUpdateTimeBlocksDialog.open(item)}}},[_c('v-list-item-title',[_vm._v(" Edit Time Block ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteTimeBlock(item.id)}}},[_c('v-list-item-title',{staticClass:"red--text text--darken-3"},[_vm._v(" Delete Time Block ")])],1)],1)],1)]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.cancel}},[_vm._v("Close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }