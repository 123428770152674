<template>
  <v-autocomplete
    :id="id"
    class="filter-input"
    v-model="selectedOptions"
    :items="items"
    placeholder="Select many..."
    filled
    multiple
    small-chips
    clearable
    item-text="text"
    item-value="value"
    @input="$emit('input', selectedOptions)"
    :disabled="items.length === 0"
  ></v-autocomplete>
</template>
<script>
export default {
  name: 'FilterAutocomplete',
  props: ['id', 'items'],
  data: () => ({
    selectedOptions: []
  }),
  methods: {
    setValues (items) {
      this.selectedOptions = items
    },
    reset () {
      this.selectedOptions = []
    }
  }
}
</script>
