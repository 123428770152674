<template>
  <v-dialog v-model="dialog" max-width="600" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <!-- Change Title to 'Update Patient History' when applicable -->
      <v-card-title class="primary white--text">Patient Note</v-card-title>
      <v-card-text>
        <v-form ref="form" class="mt-4">
          <v-textarea
            v-model="patientLog.note"
            filled
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancel">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import RulesMixin from '@/mixins/RulesMixin'
import structuredClone from '@ungap/structured-clone'
export default {
  name: 'UpdatePatientHistoryDialog',
  mixins: [RulesMixin],
  data: () => ({
    dialog: false,
    patientLog: {}
  }),
  methods: {
    open (log) {
      this.patientLog = structuredClone(log)
      this.dialog = true
    },
    cancel () {
      this.dialog = false
    }
  }
}
</script>
