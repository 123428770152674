<template>
  <div>
    <CreateProviderPatientAppointmentDialog ref="createAppointmentDialog" :calendar-events="calendarEvents" />
    <v-dialog @click:outside="cancel" :value="dialog" max-width="800" :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card>
        <v-card-title class="primary white--text">Patient Search</v-card-title>
        <v-card-text>
          <v-data-table style="min-height: 631px"
                        :search="search"
                        :headers="headers"
                        :items="patients"
                        :server-items-length="totalPatientsCount"
                        @update:options="getPatients"
                        :footer-props="{'items-per-page-options': [25, 50, 100]}">
            <template v-slot:top>
              <v-row class="mt-2">
                <v-col cols="4">
                  <search-bar ref="searchBar" @child-method="updateSearchInput" :hint="'When searching DOB use format: YYYY-MM-DD'"/>
                </v-col>
                <v-col cols="4">
                  <v-select
                    ref="statusSelect"
                    :items="caseStatusOptions"
                    item-text="text"
                    item-value="value"
                    v-model="selectedCaseStatus"
                    label="Case Status"
                    dense filled
                    class="ml-3 CaseStatus"
                    hide-details
                    clearable
                    @change="getPatients"
                    @click:clear="resetStatusSelect"
                  />
                </v-col>
                <v-col cols="4">
                  <v-select
                    ref="locationSelect"
                    :items="updatedLocations"
                    item-text="name"
                    item-value="id"
                    v-model="selectedLocation"
                    label="Facility"
                    dense filled
                    class="ml-3 CaseStatus"
                    hide-details
                    clearable
                    @input="getPatients"
                    @click:clear="resetLocationSelect"
                  />
                </v-col>
              </v-row>
            </template>
            <template #item.alerts="{ item }">
              <v-tooltip right color="error" v-if="item.alerts.length > 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="ml-2" color="error">fas fa-exclamation-triangle</v-icon>
                </template>
                <ul v-for="(alert, index) in item.alerts" :key="index">
                  <li>{{alert.alert}}</li>
                </ul>
              </v-tooltip>
            </template>
            <template #item.dob="{ item }">
              {{ formatCarbonDateNoSetTime(item.dob) }}
            </template>
            <template #item.main_phone="{ item }">
              {{ formatPhone(item.main_phone) }}
            </template>
            <template #item.case.status="{ item }">
              {{ capitalizeFirstWord(item.case.status) }}
            </template>
            <template #item.case.location.name="{ item }">
              {{ item.case.location.name }}
            </template>
            <template #item.actions="{ item }">
              <v-menu>
                <template #activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>fas fa-ellipsis-h</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="routeToPatientChart(item)">
                    <v-list-item-title>Patient Chart</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="routeToPatientProfile(item)" v-if="hasAccess([Roles.ADMIN, Roles.BASIC, Roles.CLINIC_STAFF])">
                    <v-list-item-title>Patient Profile</v-list-item-title>
                  </v-list-item>
                      <v-tooltip :disabled="item.alerts.length === 0" left color="error">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <v-list-item
                              @click="$refs.createAppointmentDialog.open(item)"
                              :disabled="item.alerts.length > 0"
                              v-if="hasAccess([Roles.ADMIN, Roles.BASIC, Roles.CLINIC_STAFF, Roles.PROVIDER])"
                            >
                              <v-list-item-title>Create Appointment</v-list-item-title>
                            </v-list-item>
                          </span>
                        </template>
                        <span>{{item.full_name + ' currently has a hold'}}</span>
                      </v-tooltip>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex">
              <v-btn @click="cancel">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import PhoneMixin from '@/mixins/PhoneMixin'
import SearchBar from '@/components/FormFields/SearchBar.vue'
import CreateProviderPatientAppointmentDialog
  from '@/components/ProviderPatients/Dialogs/CreateProviderPatientAppointmentDialog.vue'
import { formatCarbonDateNoSetTime } from '@/js/PatientIntake/functions'
import DateMixin from '@/mixins/DateMixin'
import { CaseStatusOptions } from '@/js/CaseStatuses'
import { mapActions, mapGetters, mapState } from 'vuex'
import { capitalizeFirstWord } from '@/js/functions'
import event, { Events } from '@/event'
import { Roles } from '@/js/Roles'
import structuredClone from '@ungap/structured-clone'

export default {
  name: 'SearchPatientDialog',
  components: { SearchBar, CreateProviderPatientAppointmentDialog },
  mixins: [PhoneMixin, DateMixin],
  props: ['calendarEvents'],
  data: () => ({
    Roles,
    dialog: false,
    search: '',
    headers: [
      { text: 'Alerts', value: 'alerts' },
      { text: 'Last Name', value: 'last_name' },
      { text: 'First Name', value: 'first_name' },
      { text: 'DOB', value: 'dob' },
      { text: 'Phone', value: 'main_phone', sortable: false },
      { text: 'Case Status', value: 'case.status' },
      { text: 'Location', value: 'case.location.name' },
      { text: 'Quick Actions', value: 'actions', sortable: false }
    ],
    caseStatusOptions: [],
    selectedCaseStatus: 'active',
    updatedLocations: [],
    selectedLocation: 'all',
    filterParams: {
      page: '',
      limit: '',
      orderBy: '',
      orderDir: '',
      search: ''
    }
  }),
  computed: {
    ...mapState({
      patients: state => state.ProviderPatient.searchedPatients,
      totalPatientsCount: state => state.ProviderPatient.totalSearchedPatientsCount,
      locations: state => state.Organization.locations
    }),
    ...mapGetters({
      userId: 'User/getId',
      url: 'Organization/apiUrl',
      hasAccess: 'User/hasAccess',
      patientsSearchParams: 'ProviderPatient/getPatientsSearchParams'
    })
  },
  methods: {
    ...mapActions('ProviderPatient', ['setPatientsSearchParams']),
    capitalizeFirstWord,
    formatCarbonDateNoSetTime,
    updateSearchInput (valueFromChild) {
      this.search = valueFromChild
    },
    open () {
      this.dialog = true
      if (this.options) {
        this.getPatients()
      }
    },
    cancel () {
      this.dialog = false
      this.search = ''
      this.$refs.searchBar.reset()
      this.selectedCaseStatus = 'active'
      this.selectedLocation = 'all'
      this.$emit('search-patient-dialog-closing')
    },
    updateLocations () {
      this.$store.dispatch('Organization/getLocations')
      this.updatedLocations = structuredClone(this.locations)
      this.updatedLocations.unshift({ name: 'All', id: 'all' })
    },
    resetLocationSelect () {
      this.$nextTick(() => {
        this.selectedLocation = 'all'
      })
    },
    updateCaseStatusOptions () {
      this.caseStatusOptions = structuredClone(CaseStatusOptions)
      this.caseStatusOptions.unshift({ text: 'All', value: 'all' })
    },
    resetStatusSelect () {
      this.$nextTick(() => {
        this.selectedCaseStatus = 'active'
      })
    },
    getPatients (options) {
      this.$store.commit('loading', true)

      if (options && typeof (options) === 'object') {
        this.options = options
      }

      if (this.search) {
        this.search = this.search.toString().toLowerCase()
      }

      const sort = this.options && (
        this.options.sortDesc.length === 0 ||
        this.options.sortDesc[0] === false ||
        this.options.sortDesc[0] === 'asc'
      ) ? 'asc' : 'desc'

      this.filterParams.search = this.search
      this.filterParams.page = this.options ? this.options.page : 1
      this.filterParams.limit = this.options ? this.options.itemsPerPage : 25
      this.filterParams.orderBy = this.options ? this.options.sortBy : null
      this.filterParams.orderDir = sort
      this.filterParams.case_status = this.selectedCaseStatus || 'active'
      this.filterParams.location_id = this.selectedLocation

      // Need to set the state to the search params for toggling of active location
      this.setPatientsSearchParams(this.filterParams)

      event.emit(Events.LOADING, true)

      this.$store
        .dispatch('ProviderPatient/getSearchedPatients', this.filterParams)
        .then(() => {
          this.$store.commit('loading', false)
        }).finally(() => { event.emit(Events.LOADING, false) })
    },
    routeToPatientChart (patient) {
      this.$router.push({ name: 'PatientChartEditView', params: { patientId: patient.id.toString() } })
    },
    routeToPatientProfile (patient) {
      this.$router.push({ name: 'CaseView', params: { caseId: patient.case.id.toString() } })
    }
  },
  watch: {
    search: {
      handler: function () {
        this.getPatients()
      },
      deep: true
    }
  },
  mounted () {
    this.updateLocations()
    this.updateCaseStatusOptions()
  }
}
</script>
<style scoped>
.v-tooltip__content {
  opacity: 1 !important;
}
</style>
