var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('UpdatePatientHistoryDialog',{ref:"updatePatientHistoryDialog"}),_c('v-data-table',{attrs:{"items":_vm.logs,"headers":_vm.logHeaders,"disable-sort":true},on:{"click:row":function($event){return _vm.$refs.updatePatientHistoryDialog.open($event)}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCarbonDate(item.created_at))+" ")]}},{key:"item.userName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.name)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.action)+" ")]}},{key:"item.appointmentId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.appointment_id ? item.appointment_id : '---')+" ")]}},{key:"item.patientNote",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatPatientNote(item.note)))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }