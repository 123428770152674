<template>
  <v-dialog v-model="dialog" max-width="600" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title class="dialog-banner">Telephone Encounter</v-card-title>
      <v-card-text class="mt-6">
        <v-form ref="form" v-model="formValid" v-if="providerOptions.length > 0">
          <v-row dense>
            <v-col>
              <date-picker
                  ref="datepicker"
                  :date="encounter.encounter_date"
                  :default-to-today="false"
                  label="Encounter Date"
                  @date-change="setEncounterDate"
                  :dateValid="dateValid"
                  :rules="[rules.required]"
              />
            </v-col>
            <v-col>
              <v-autocomplete
                  :items="providerOptions"
                  v-model="encounter.provider_option"
                  item-text="provider_name"
                  item-value="id"
                  filled
                  label="Provider"
                  :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-textarea
              v-model="encounter.note"
              label="Telephone Encounter Note"
              placeholder="Please describe the encounter..."
              filled
              :rules="[rules.required]"
          />
          <div>
            <!--  Providers can edit the response -->
            <v-textarea
                v-if="encounter.provider_option === providerId.toString()"
                label="Provider Response"
                v-model="encounter.provider_response"
                filled
                :rules="[rules.required]"
            />
            <!--  Response viewable for others -->
            <v-textarea
                v-if="encounter.provider_response && encounter.provider_option !== providerId.toString()"
                label="Provider Response"
                v-model="encounter.provider_response"
                filled
            />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn text @click="cancel">Cancel</v-btn>
        <v-btn depressed color="primary" @click="updateEncounter">Update</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import event, { Events } from '@/event'
import { mapGetters } from 'vuex'
import RulesMixin from '@/mixins/RulesMixin'
import structuredClone from '@ungap/structured-clone'
import DatePicker from '@/components/PatientIntake/DatePicker.vue'
import { ProviderOption } from '@/js/Provider/TelephoneEncounter'

export default {
  name: 'UpdateTelephoneEncounterDialog',
  mixins: [RulesMixin],
  components: { DatePicker },
  data: () => ({
    ProviderOption,
    providerOptions: [],
    formValid: true,
    dateValid: true,
    encounter: {},
    dialog: false
  }),
  computed: {
    ...mapGetters({
      patient: 'ProviderPatient/patientDemographics',
      providers: 'Organization/providers',
      providerId: 'User/getProviderId'
    })
  },
  methods: {
    open (encounter) {
      this.encounter = structuredClone(encounter)
      this.getProviderOptions()
      this.dialog = true
    },
    cancel () {
      this.reset()
    },
    reset () {
      this.encounter = {}
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.providerOptions = []
      this.formValid = true
      this.dateValid = true
      this.dialog = false
    },
    async getProviderOptions () {
      // Only acknowledgement options are available to acknowledge telephone encounters
      if (this.encounter.provider_option === ProviderOption.ALL_LOCATION_PROVIDERS.id ||
          this.encounter.provider_option === ProviderOption.ALL_PATIENT_PROVIDERS.id) {
        this.providerOptions = [
          { header: 'Multiple Providers (Acknowledgement)' },
          { divider: true },
          ProviderOption.ALL_LOCATION_PROVIDERS,
          ProviderOption.ALL_PATIENT_PROVIDERS
        ]
        return
      }

      // only individual providers (response required) is available if individual provider assigned
      await this.getProviders()
    },
    async getProviders () {
      await this.$store.dispatch('Organization/getProviders')
      this.providerOptions = [
        { header: 'Individual Providers (Response Required)' },
        { divider: true }
      ]

      // provider drop down wont match an int id - convert to string
      let providers = structuredClone(this.providers)
      providers = providers.map(provider => {
        provider.id = provider.id.toString()
        return provider
      })

      this.providerOptions = this.providerOptions.concat(providers)
    },
    validateDate () {
      this.dateValid = true
      if (!this.encounter.encounter_date) {
        this.dateValid = 'Required'
      }
    },
    setEncounterDate (value) {
      this.encounter.encounter_date = value
    },
    async updateEncounter () {
      this.$refs.form.validate()
      this.validateDate()

      if (!this.formValid || this.dateValid === 'Required') {
        return
      }

      try {
        await window.axios.put(this.$store.getters['Organization/apiUrl'] + '/provider/patients/' + this.patient.id + '/telephone-encounters/' + this.encounter.id, {
          encounter: this.encounter
        })
        this.$emit('encounterChange')
        // clear the provider task
        if (this.encounter.provider_response) {
          await this.$store.dispatch('User/updateUserTasks')
        }
      } catch (error) {
        event.emit(Events.ERROR, error.response.data.payload.message, 2000)
      }

      this.cancel()
    }
  }
}
</script>
<style scoped>
.dialog-banner {
  background-color: #043561;
  color: #ffffff;
}
</style>
