<template>
  <v-navigation-drawer :value="value" @input="$emit('input', $event)" app>
    <v-sheet
      id="top-sheet"
      class="white--text pa-2"
      height="90"
      width="100%">
      <v-img src="../../assets/Telescope.svg" alt="" />
    </v-sheet>

    <v-list shaped >
      <template v-for="(item, i) in itemsWithAccess">
        <v-divider :key="i" v-if="item.divider || false" />
        <v-list-item v-else :key="i" :to="item.link"  class="pr-0">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-if="item.name === 'Provider Schedules' && userTasks.length > 0" class="pt-1">
              {{ item.name }}
              <v-badge
                :content="userTasks.length"
                class="ml-1"
              >
              </v-badge>
            </v-list-item-title>
            <v-list-item-title v-else>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>

<!--  TODO remove if when individuals use their individual portal  -->
    <template #append v-if="hasAccess([Roles.ADMIN, Roles.BASIC, Roles.CLINIC_STAFF, Roles.PROVIDER]) && !$store.getters['Organization/isIndividual']">
      <v-list shaped>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>fas fa-sign-out-alt</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>
<script>

import { mapGetters } from 'vuex'
import { Roles } from '@/js/Roles'

export default {
  name: 'LeftSidebar',
  components: {},
  props: {
    value: {
      type: Boolean,
      default () {
        return this.$vuetify.breakpoint.lgAndUp
      },
      required: false
    }
  },
  computed: {
    ...mapGetters({
      hasAccess: 'User/hasAccess',
      userTasks: 'User/getUserTasks'
    }),
    itemsWithAccess () {
      const items = []

      this.allMenuItems.forEach(i => {
        if (i.show && i.divider) {
          items.push(i)
        }

        if (i.show && this.hasAccess(i.roles)) {
          items.push(i)
        }
      })

      return items
    }
  },
  mounted () {
    this.checkTaskInterval()
  },
  data () {
    return {
      currentLocationId: null,
      updatedLocations: [],
      Roles,
      allMenuItems: [
        // Dashboard
        // TODO when individuals view dashboard add Roles.Individual
        {
          name: 'Dashboard',
          icon: 'fas fa-chart-area',
          link: '/',
          roles: [Roles.ADMIN, Roles.BASIC, Roles.CLINIC_STAFF],
          show: this.$store.getters['Organization/usesDashboard']
        },
        // Clinic Admin
        {
          name: 'All Cases',
          icon: 'fas fa-users',
          link: '/cases/active',
          roles: [Roles.ADMIN, Roles.BASIC, Roles.CLINIC_STAFF],
          show: this.$store.getters['Organization/isHealthClinic']
        },
        {
          name: 'Intakes',
          icon: 'fas fa-notes-medical',
          link: '/cases/intakes',
          roles: [Roles.ADMIN, Roles.BASIC, Roles.CLINIC_STAFF],
          show: this.$store.getters['Organization/isHealthClinic']
        },
        {
          name: 'Compliance',
          icon: 'fas fa-book-medical',
          link: '/cases/compliance',
          roles: [Roles.ADMIN, Roles.BASIC],
          show: this.$store.getters['Organization/isHealthClinic']
        },
        {
          name: 'Bills & Records',
          icon: 'fas fa-file-medical',
          link: '/cases/billing',
          roles: [Roles.ADMIN, Roles.BASIC],
          show: this.$store.getters['Organization/isHealthClinic']
        },
        {
          name: 'Accounts Receivable',
          icon: 'fas fa-file-invoice-dollar',
          link: '/cases/accounts-receivable',
          roles: [Roles.ADMIN, Roles.BASIC],
          show: this.$store.getters['Organization/isHealthClinic']
        },
        {
          name: 'Provider Schedules',
          icon: 'fas fa-calendar-alt',
          link: '/provider-schedules',
          roles: [Roles.ADMIN, Roles.CLINIC_STAFF, Roles.BASIC, Roles.PROVIDER],
          show: this.$store.getters['Organization/isHealthClinic']
        },
        {
          show: this.$store.getters['Organization/isHealthClinic'],
          divider: true
        },
        {
          name: 'Polaris',
          icon: 'fas fa-briefcase-medical',
          link: '/cases/polaris',
          roles: [Roles.ADMIN, Roles.BASIC],
          show: this.$store.getters['Organization/isHealthClinic']
        },
        // attorney links
        {
          name: 'Cases',
          icon: 'fas fa-briefcase-medical',
          link: '/attorney/cases',
          roles: [Roles.ADMIN, Roles.BASIC],
          show: this.$store.getters['Organization/isLawFirm']
        },
        // individual (patient) links
        {
          name: 'Patient Intake Form',
          icon: 'fas fa-notes-medical',
          link: '/patient/intake',
          roles: [Roles.INDIVIDUAL],
          show: this.$store.getters['Organization/isIndividual']
        }
      ]
    }
  },
  methods: {
    logout () {
      this.$store
        .dispatch('User/logout')
        .then(() => {
          this.$router.push({ name: 'Login' })
        })
    },
    checkTaskInterval () {
      this.$store.dispatch('User/updateTaskInterval')
    }
  }
}
</script>
