export const ProviderTypes = {
  NP: { text: 'Nurse Practitioner (NP)', value: 'NP' },
  PT: { text: 'Physical Therapist (PT)', value: 'PT' },
  PTA: { text: 'Physical Therapy Assistant (PTA)', value: 'PTA' },
  MD: { text: 'Medical Doctor (MD)', value: 'MD' }
}

export const ProviderOptions = [
  ProviderTypes.NP,
  ProviderTypes.PT,
  ProviderTypes.PTA,
  ProviderTypes.MD
]
