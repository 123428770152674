<template>
  <v-dialog v-model="dialog" max-width="400" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title class="primary white--text">Encounter Confirmation</v-card-title>
      <v-card-text class="pt-3">
        I, {{providerName}}, verify that all SOAP fields are complete and am signing off on this encounter.
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn text @click="cancel">Cancel</v-btn>
        <v-btn @click="sign" color="primary">Sign</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import RulesMixin from '@/mixins/RulesMixin'
export default {
  name: 'AppointmentNoteDialog',
  mixins: [RulesMixin],
  data: () => ({
    dialog: false,
    providerName: ''
  }),
  methods: {
    open (name) {
      this.providerName = name
      this.dialog = true
    },
    cancel () {
      this.providerName = ''
      this.dialog = false
    },
    sign () {
      this.$emit('sign')
      this.cancel()
    }
  }
}
</script>
