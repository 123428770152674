import QueryString from '../../library/QueryString'

export default {
  namespaced: true,
  state: {
    patient: {},
    referrals: [],
    documents: [],
    alerts: [],
    encounters: [],
    logs: [],
    currentProviderEncounter: {},
    // patient search dialog
    searchedPatients: [],
    totalSearchedPatientsCount: 0,
    patientsSearchParams: {}
  },
  getters: {
    patientDemographics (state) {
      return state.patient
    },
    patientDocuments (state) {
      return state.documents
    },
    patientReferrals (state) {
      return state.referrals
    },
    getPatientsSearchParams (state) {
      return state.patientsSearchParams
    },
    patientAlerts (state) {
      return state.alerts
    },
    patientEncounters (state) {
      return state.encounters
    },
    patientLogs (state) {
      return state.logs
    },
    currentProviderEncounter (state) {
      return state.currentProviderEncounter
    }
  },
  mutations: {
    setCurrentPatient (state, patient) {
      state.patient = patient
      state.alerts = patient.alerts
      state.logs = patient.logs
      state.referrals = patient.referrals
      state.documents = patient.case.files
      state.encounters = patient.encounters
    },
    setSearchedPatients (state, patients) {
      state.searchedPatients = patients
    },
    setSearchedPatientsCount (state, count) {
      state.totalSearchedPatientsCount = count
    },
    setPatientsSearchParams (state, payload) {
      state.patientsSearchParams = payload
    },
    setCurrentProviderEncounter (state, payload) {
      state.currentProviderEncounter = payload
    }
  },
  actions: {
    async getCurrentPatient (ctx, patientId) {
      const response = await window.axios.get(ctx.rootGetters['Organization/apiUrl'] + '/provider/patients/' + patientId)
      ctx.commit('setCurrentPatient', response.data.payload)
    },
    // get all patients for patient search
    async getSearchedPatients (ctx, params) {
      let queryString = ''
      if (params) {
        queryString = QueryString.format(params)
      }
      const response = await window.axios.get(ctx.rootGetters['Organization/apiUrl'] + '/provider/patients' + encodeURI(queryString))
      ctx.commit('setSearchedPatients', response.data.payload.patients)
      ctx.commit('setSearchedPatientsCount', response.data.payload.total_patients_count)

      return response.data.payload
    },
    setPatientsSearchParams (ctx, payload) {
      ctx.commit('setPatientsSearchParams', payload)
    },
    async getProviderEncounter (ctx, { patientId, encounterId }) {
      const response = await window.axios.get(ctx.rootGetters['Organization/apiUrl'] + '/provider/patients/' + patientId + '/provider-encounters/' + encounterId)
      if (response.data.payload.provider_encounter.filtered_diagnoses) {
        response.data.payload.provider_encounter.filtered_diagnoses = JSON.parse(response.data.payload.provider_encounter.filtered_diagnoses)
      }
      ctx.commit('setCurrentProviderEncounter', response.data.payload.provider_encounter)
    }
  }
}
