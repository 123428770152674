<template>
  <v-dialog v-model="dialog" max-width="800" :fullscreen="$vuetify.breakpoint.xsOnly">
    <CreateAndUpdateTimeBlocksDialog
      ref="createAndUpdateTimeBlocksDialog"
      @fetch-time-blocks="fetchTimeBlocks"
      :calendar-events="calendarEvents"
      :selected-provider-id="selectedProvider"
      :selected-location-id="locationId"
    />
    <AppointmentNoteDialog ref="appointmentNoteDialog"/>
    <v-card>
      <v-card-title class="primary white--text">Manage Provider Time Blocks</v-card-title>
      <v-card-text class="pa-5">
        <v-select
          v-model="selectedProvider"
          label="Select a provider"
          :items="locationProviders"
          item-value="id"
          item-text="pretty_provider"
          placeholder="Provider"
          filled
          @input="updateProviderTimeBlocks"
        />
        <div class="my-3 d-flex justify-end">
          <v-btn small color="primary" @click="$refs.createAndUpdateTimeBlocksDialog.open()">
            Add Time Block
          </v-btn>
        </div>
        <h3>Active Time Blocks</h3>
        <v-data-table
          :items="selectedProviderTimeBlocks"
          :headers="timeBlockHeaders"
          :disable-sort="true"
          class="mt-2"
        >
          <template #item.start_date="{ item }">
            {{ formatCarbonDateNoSetTime(item.start_date)}}
          </template>
          <template #item.start_time="{ item }">
            {{ item.start_time}}
          </template>
          <template #item.end_time="{ item }">
            {{ item.end_time}}
          </template>
          <template #item.is_ongoing="{ item }">
            {{ item.end_date ? 'Yes' : 'No'}}
          </template>
          <template #item.end_date="{ item }">
            {{ item.end_date ? formatCarbonDateNoSetTime(item.end_date) : '---'}}
          </template>
          <template #item.days_of_week="{ item }">
            {{ item.end_date ? formatDaysOfWeek(item.days_of_week) : '---'}}
          </template>
          <template #item.actions="{ item }">
            <v-menu>
              <template #activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>fas fa-ellipsis-h</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="$refs.createAndUpdateTimeBlocksDialog.open(item)">
                  <v-list-item-title>
                    Edit Time Block
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteTimeBlock(item.id)">
                  <v-list-item-title class="red--text text--darken-3">
                    Delete Time Block
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
<!--          <template #item.notes="{ item }">-->
<!--            <span @click="$refs.appointmentNoteDialog.open(item.notes)">{{ formatNote(item.notes) }}</span>-->
<!--          </template>-->
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancel">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import CreateAndUpdateTimeBlocksDialog from '@/components/ProviderPatients/Dialogs/CreateAndUpdateTimeBlocksDialog.vue'
import event, { Events } from '@/event'
import { formatCarbonDateNoSetTime } from '@/js/PatientIntake/functions'
import AppointmentNoteDialog from '@/components/ProviderPatients/Dialogs/AppointmentNoteDialog.vue'

export default {
  name: 'ManageProviderTimeBlocksDialog',
  components: { CreateAndUpdateTimeBlocksDialog, AppointmentNoteDialog },
  props: ['locationProviders', 'locationId', 'calendarEvents'],
  data: () => ({
    dialog: false,
    timeBlocks: [],
    selectedProviderTimeBlocks: [],
    timeBlockHeaders: [
      { text: 'Start Date', value: 'start_date' },
      { text: 'Start Time', value: 'start_time' },
      { text: 'End Time', value: 'end_time' },
      { text: 'Ongoing', value: 'is_ongoing' },
      { text: 'End Date', value: 'end_date' },
      { text: 'Days Of Week', value: 'days_of_week' },
      { text: 'Actions', value: 'actions' }
      // { text: 'Notes', value: 'notes' }
    ],
    selectedProvider: null
  }),
  methods: {
    formatCarbonDateNoSetTime,
    async open () {
      await this.fetchTimeBlocks()
      if (this.locationProviders.length > 0) {
        this.selectedProvider = this.locationProviders[0].id
        this.updateProviderTimeBlocks()
      }
      this.dialog = true
    },
    cancel () {
      this.$emit('manage-provider-time-blocks-dialog-closing')
      this.dialog = false
    },
    updateProviderTimeBlocks () {
      this.selectedProviderTimeBlocks = this.timeBlocks.filter((timeBlock) => timeBlock.provider_id === this.selectedProvider)
    },
    async fetchTimeBlocks () {
      try {
        const rawData = await window.axios.get(this.$store.getters['Organization/apiUrl'] + '/provider/schedule/time-blocks/' + this.locationId)
        this.timeBlocks = rawData.data.payload['time-blocks'].filter((timeBlock) => timeBlock.location_id === this.locationId)
        this.updateProviderTimeBlocks()
      } catch (error) {
        event.emit(Events.ERROR, error.response.data.payload.message, 2000)
      }
    },
    async deleteTimeBlock (timeBlockId) {
      event.emit(Events.CONFIRM, 'Are you sure you want to permanently delete this time block?', async () => {
        try {
          await window.axios.delete(this.$store.getters['Organization/apiUrl'] + '/provider/schedule/time-blocks/' + timeBlockId)
          event.emit(Events.SUCCESS, 'Time Block deleted successfully')
          this.cancel()
        } catch (error) {
          event.emit(Events.ERROR, error.response.data.payload.message, 2000)
        }
      }, { ok: 'Yes, delete time block', cancel: 'Cancel', title: 'Are you sure?' })
    },
    formatDaysOfWeek (daysOfWeekString) {
      const daysOfWeekArray = daysOfWeekString.split(',')

      if (daysOfWeekArray.length === 1) {
        return daysOfWeekArray.join(',')
      }

      const revisedDaysOfWeekArray = []
      daysOfWeekArray.forEach((day) => {
        if (day === 'Tuesday') {
          revisedDaysOfWeekArray.push('Tu')
          return
        }
        if (day === 'Thursday') {
          revisedDaysOfWeekArray.push('Th')
          return
        }
        revisedDaysOfWeekArray.push(day.substring(0, 1))
      })
      return revisedDaysOfWeekArray.join(',')
    }
    // formatNote (note) {
    //   if (!note) {
    //     return '---'
    //   }
    //   if (note.length > 60) {
    //     return note.substring(0, 60) + '...'
    //   } else {
    //     return note
    //   }
    // }
  }
}
</script>
