<template>
<v-container>
  <v-row>
    <v-col>
      <v-btn to="/organization/settings/users" color="secondary">
        <v-icon small left>
          fa-arrow-left
        </v-icon>
        Back to list
      </v-btn>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              <h2>
                User Profile
              </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field filled label="Name" disabled :value="user.name" />
            </v-col>
            <v-col>
              <v-text-field filled label="Email" disabled :value="user.email" />
            </v-col>
          </v-row>
          <v-row v-if="userIsProvider">
            <v-col cols="6">
              <v-autocomplete
                label="Location"
                v-model="selectedProviderLocations"
                :disabled="loading"
                :items="updatedProviderLocations"
                item-text="name"
                item-value="id"
                class="my-6"
                multiple
                small-chips
                filled
                dense
                clearable
                :rules="[rules.requiredMultiSelect]"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                label="Provider Type"
                v-model="selectedProviderType"
                :disabled="loading"
                :items="ProviderOptions"
                class="my-6"
                filled
                persistent-hint
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-card>
        <v-card-text>
          <h2>
            User Role
          </h2>
          <v-select :disabled="(user.id === currentId) || userIsProvider" v-model="selectedRole" :items="availableRoles" />
          <span v-if="user.id === currentId">
            You cannot change your own role.
          </span>
          <span v-if="userIsProvider">
            A user's role can not be changed for a provider
          </span>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-row class="d-flex justify-end mt-5 mr-3">
    <v-btn @click="updateUserProfile" :loading="loading" :disabled="enableInvite">
      Update
    </v-btn>
  </v-row>
</v-container>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import event, { Events } from '../../../event'
import { Roles, RoleOptions } from '@/js/Roles'
import { ProviderOptions } from '@/js/ProviderTypes'
import RulesMixin from '@/mixins/RulesMixin'

export default {
  data: () => ({
    RoleOptions,
    loading: false,
    user: {},
    availableRoles: [],
    selectedRole: '',
    ProviderOptions,
    selectedProviderType: '',
    updatedProviderLocations: [],
    selectedProviderLocations: null,
    userIsProvider: false
  }),
  mixins: [RulesMixin],
  computed: {
    ...mapState({
      providerLocations: state => state.Organization.locations,
      currentId: state => state.User.id
    }),
    ...mapGetters({
      url: 'Organization/apiUrl'
    }),
    enableInvite () {
      if (this.user.id === this.currentId) {
        return true
      }

      if (this.userIsProvider) {
        if (this.selectedProviderLocations.length === 0) {
          return true
        }
      } else {
        if (this.selectedRole === this.user.role) {
          return true
        }
      }
      return false
    }
  },
  created () {
    this.getUser()
  },
  methods: {
    getUser () {
      this.$axios.get(`${this.url}/user/${this.$route.params.id}`)
        .then(r => {
          this.user = r.data.payload
          this.selectedRole = this.user.role
          this.availableRoles = RoleOptions.filter(role => role.value !== Roles.INDIVIDUAL && role.value !== Roles.PROVIDER && role.value !== Roles.NO_ACCESS)

          if (this.user.provider) {
            this.userIsProvider = true
            this.updateProviderLocations()
            this.availableRoles = RoleOptions.filter(role => role.value === Roles.PROVIDER)
            this.selectedProviderType = this.user.provider.type
            this.selectedProviderLocations = this.user.provider.locations.map((location) => location.id)
          }
        })
    },
    updateUserProfile () {
      this.loading = true

      const payload = {
        role: this.selectedRole
      }

      if (this.userIsProvider) {
        payload.location_ids = this.selectedProviderLocations
        payload.provider_type = this.selectedProviderType
      }

      this.$axios.put(`${this.url}/user/${this.$route.params.id}`, payload)
        .then(() => {
          event.emit(Events.SUCCESS, 'User profile updated!')
          this.getUser()
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateProviderLocations () {
      this.updatedProviderLocations = this.providerLocations.filter((location) => location.id !== 'all')
    }
  }
}
</script>
