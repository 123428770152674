<template>
  <v-menu
    filled
    dense
    ref="timeMenu"
    v-model="showPicker"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="inputTime"
        filled
        :placeholder="placeholder ? placeholder : 'Select a time'"
        readonly
        v-bind="attrs"
        v-on="on"
        :class="classItems"
        :style="styleItems"
        @blur="lostFocus"
        :rules="[
          !!pickerTime || timeValid
        ]"
        :disabled="disabled"
      ><template v-slot:append>
        <v-icon v-on="on">
          mdi-clock
        </v-icon>
      </template></v-text-field>
    </template>
    <v-time-picker
      v-if="showPicker"
      v-model="pickerTime"
      :allowed-minutes="allowedTimeStep"
      full-width
      scrollable
      @input="inputChanged"
      @click:minute="$refs.timeMenu.save(pickerTime)"
      :disabled="disabled"
      max="17:00"
      min="8:00"
    ></v-time-picker>
  </v-menu>
</template>
<script>

export default {
  name: 'TimePicker',
  props: {
    time: String,
    label: String,
    placeholder: String,
    classItems: String,
    styleItems: String,
    timeValid: {
      type: [Boolean, String],
      default: true
    },
    disabled: Boolean
  },
  data: () => ({
    inputTime: '',
    showPicker: false,
    pickerTime: '12:00'
  }),
  methods: {
    inputChanged () {
      this.inputTime = this.convertedDayAndTimeString()
      const timeAndDay = this.convertedDayAndTime()
      this.$emit('time-change', {
        time: timeAndDay.time,
        day: timeAndDay.day
      })
    },
    clearInput () {
      this.pickerTime = null
    },
    checkTime () {
      if (this.time) {
        this.inputTime = this.time
        this.pickerTime = this.convertTimeToPickerTime(this.time)
      }
    },
    allowedTimeStep: m => m % 15 === 0,
    convertTimeToPickerTime (time) {
      if (!time) {
        return
      }

      let [hours, minutes] = time.split(/[:\s]/g)
      const period = time.match(/am|pm/i)

      if (period[0].toLowerCase() === 'pm' && hours !== '12') {
        hours = Number(hours) + 12
      } else if (period[0].toLowerCase() === 'am' && hours === '12') {
        hours = '00'
      }

      return `${hours}:${minutes}`
    },
    convertedDayAndTime: function () {
      if (!this.pickerTime) {
        return
      }

      const splitTime = this.pickerTime.split(':')
      const hour = +splitTime[0]
      const minute = splitTime[1]

      let updatedTimeAndDay
      switch (true) {
        case hour === 0:
          updatedTimeAndDay = {
            time: `12:${minute}`,
            day: 'AM'
          }
          break
        case hour < 12:
          updatedTimeAndDay = {
            time: `${hour}:${minute}`,
            day: 'AM'
          }
          break
        case hour === 12:
          updatedTimeAndDay = {
            time: `${hour}:${minute}`,
            day: 'PM'
          }
          break
        case hour > 12:
          updatedTimeAndDay = {
            time: `${hour - 12}:${minute}`,
            day: 'PM'
          }
      }
      return updatedTimeAndDay
    },
    lostFocus () {
      this.$emit('blur')
    },
    convertedDayAndTimeString () {
      if (!this.pickerTime) {
        return ''
      }
      const dayAndTime = this.convertedDayAndTime()

      return dayAndTime.time + ' ' + dayAndTime.day
    }
  },
  watch: {
    time: function () {
      this.checkTime()
    }
  },
  mounted () {
    this.pickerTime = null
    this.checkTime()
  }
}
</script>
