<template>
  <v-container>
    <CreateAppointmentDialog ref="createAppointmentDialog" @reload="getCases" />
    <h1>Intake Cases</h1>
    <div>
      <v-tooltip right>
        <template #activator="{ on }">
          <v-btn small depressed fab color="primary" :to="{ name: 'CreateCase' }" v-on="on">
            <v-icon small>fa-plus</v-icon>
          </v-btn>
        </template>
        <span>create new case</span>
      </v-tooltip>
    </div>
    <div v-if="casesCount === 0" class="mt-2">
      <p>There are currently no intake cases matching the search or location</p>
    </div>
    <div>
      <div class="d-flex mt-2">
        <search-bar v-on:child-method="updateSearchInput"/>
        <v-select
          :items="IntakePaperworkStatusOptions"
          v-model="paperworkStatus"
          label="Intake Paperwork Status"
          dense filled
          class="ml-3 paperworkStatus"
          hide-details
          clearable
          @change="getCases"
        />
      </div>
      <v-data-table
        :items="cases"
        :headers="headers"
        :server-items-length="casesCount"
        @click:row="goToCase($event.id)"
        @update:options="getCases"
        :footer-props="{
      'items-per-page-options': [25, 50, 100]
    }"
      >
        <template #item.created_at="{ item }">
          {{ formatDate(item.created_at) }}
        </template>
        <template #item.status="{item}">
          {{capitalizeFirstWord(item.status)}}
        </template>
        <template #item.patient_id="{ item }">
          <div class="d-flex">
            <div class="mt-1 ml-1">{{ item.patient.first_name }} {{ item.patient.last_name }}</div>
            <v-tooltip right color="error" v-if="item.patient.alerts.length > 0">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="ml-2" color="error">fas fa-exclamation-triangle</v-icon>
              </template>
              <ul v-for="(alert, index) in item.patient.alerts" :key="index">
                <li>{{alert.alert}}</li>
              </ul>
            </v-tooltip>
          </div>
        </template>
        <template #item.location_id="{ item }">
          {{ item.location ? item.location.name : ''}}
        </template>

        <template #item.nextAppointment="{ item }">
          <span v-if="item.appointments.length > 0">{{ formatCarbonDateNoSetTime(item.appointments[0].date) }}</span>
          <span v-else><v-icon color="warning">fas fa-exclamation-circle</v-icon> no appointment set</span>
        </template>

        <template #item.actions="{ item }" v-if="hasAccess([Roles.ADMIN, Roles.BASIC])">
          <v-menu>
            <template #activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>fas fa-ellipsis-h</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="$refs.createAppointmentDialog.open(item.id, item.status)">
                <v-list-item-title>Schedule New Appointment</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>
<script>
import AddressMixin from '../../mixins/AddressMixin'
import DateMixin from '../../mixins/DateMixin'
import SearchBar from '@/components/FormFields/SearchBar'
import { Roles } from '@/js/Roles'
import { IntakePaperworkStatusOptions } from '@/js/PatientIntake/PaperworkStatus'
import CreateAppointmentDialog from '@/components/Case/Dialogs/Appointments/CreateAppointmentDialog.vue'
import GetCasesMixin from '@/mixins/GetCasesMixin'
import { mapGetters } from 'vuex'
import { formatCarbonDateNoSetTime } from '@/js/PatientIntake/functions'

export default {
  name: 'IntakesIndex',
  components: { SearchBar, CreateAppointmentDialog },
  mixins: [AddressMixin, DateMixin, GetCasesMixin],
  data () {
    return {
      IntakePaperworkStatusOptions,
      formatCarbonDateNoSetTime,
      Roles,
      paperworkStatus: '',
      headers: [
        { text: 'Created', value: 'created_at' },
        { text: 'Status', value: 'status' },
        { text: 'Patient Name', value: 'patient_id' },
        { text: 'Location', value: 'location_id' },
        { text: 'Initial Appointment', value: 'nextAppointment', sortable: false },
        { text: 'Intake Paperwork Status', value: 'intake_paperwork_status' },
        { text: 'Quick Actions', value: 'actions', sortable: false }
      ],
      filterParams: {
        status: 'intake',
        page: '',
        limit: '',
        orderBy: '',
        orderDir: '',
        patient: true,
        location: true,
        lien: false,
        scheduledAppointments: true,
        activeLocationId: '',
        search: '',
        intakePaperworkStatus: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      hasAccess: 'User/hasAccess'
    })
  },
  methods: {}
}
</script>
<style scoped>
  .paperworkStatus {
    max-width: 35% !important;
  }

  .v-tooltip__content {
    opacity: 1 !important;
  }
</style>
