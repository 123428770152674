<template>
  <div>
<!-- Dialogs   -->
    <UpdateReferralOutDialog ref="updateReferralDialog" @reload="$emit('referralChange')" />
    <DeleteReferralOutDialog ref="deleteReferralDialog" @reload="$emit('referralChange')" />
<!-- Referrals   -->
    <h3>Referrals</h3>
    <v-data-table
      :items="referrals"
      :headers="referralHeaders"
      :disable-sort="true"
      class="mt-2"
    >
      <template #item.actions="{ item }">
        <v-menu>
          <template #activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>fas fa-ellipsis-h</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="hasAccess([Roles.ADMIN, Roles.BASIC, Roles.PROVIDER])" @click="$refs.updateReferralDialog.open(item)">
              <v-list-item-title>Update Referral</v-list-item-title>
            </v-list-item>

            <v-list-item v-if="item.status !== 'Canceled' && hasAccess([Roles.ADMIN, Roles.BASIC])" @click="$refs.deleteReferralDialog.open(item)">
              <v-list-item-title class="red--text">Cancel Referral</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
import { Roles } from '@/js/Roles'
import DeleteReferralOutDialog from '@/components/Case/Dialogs/ReferralsOut/DeleteReferralOutDialog.vue'
import UpdateReferralOutDialog from '@/components/Case/Dialogs/ReferralsOut/UpdateReferralOutDialog.vue'

export default {
  components: { UpdateReferralOutDialog, DeleteReferralOutDialog },
  data: () => ({
    Roles
  }),
  computed: {
    ...mapGetters({
      patient: 'ProviderPatient/patientDemographics',
      referrals: 'ProviderPatient/patientReferrals',
      url: 'Organization/apiUrl',
      hasAccess: 'User/hasAccess'
    }),
    referralHeaders () {
      const headers = [
        { text: 'Referral ID', value: 'id' },
        { text: 'Type', value: 'type' },
        { text: 'Treatment', value: 'treatment' },
        { text: 'Body Part', value: 'body_part' },
        { text: 'Status', value: 'status' }
      ]

      if (this.hasAccess([Roles.CLINIC_STAFF])) {
        return headers
      }
      return headers.concat([{ text: 'Quick Actions', value: 'actions' }])
    }
  },
  methods: {}
}
</script>
