
export const PatientAlerts = {
  HOLD_PROVIDER: 'Hold Tx Per Provider',
  HOLD_ATTORNEY: 'Hold Tx Per Attorney'
}

export const PatientAlertOptions = [
  PatientAlerts.HOLD_PROVIDER,
  PatientAlerts.HOLD_ATTORNEY
]
