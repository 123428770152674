<style>
  .search-input{
    max-width: 400px;
  }
</style>
<template>
  <v-text-field
    label="Search"
    single-line
    prepend-inner-icon="fa-search"
    class="my-2 mx-1 search-input"
    v-model="search"
    :hint="hint"
  ></v-text-field>
</template>

<script>

export default {
  name: 'SearchBar',
  props: {
    hint: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      search: ''
    }
  },
  methods: {
    reset () {
      this.search = ''
    }
  },
  watch: {
    search: {
      handler: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.$emit('child-method', this.search)
        }, 600)
      }
    }
  }
}
</script>
