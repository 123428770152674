<script>
import { VBtn, VIcon, VAutocomplete } from 'vuetify/lib'
import FilteredAutocomplete from '@/components/Provider/FilteredAutocomplete.vue'
import FilterAutocomplete from '@/components/Provider/FilterAutocomplete.vue'
export default {
  name: 'EncounterTemplate',
  props: ['template', 'filteredDiagnoses'],
  components: { VBtn, VIcon, VAutocomplete, FilterAutocomplete, FilteredAutocomplete },
  data: () => ({
    refList: [],
    rawText: '',
    textOptions: null,
    inputValues: {},
    filter: null
  }),
  methods: {
    prepareTextArray () {
      const textRegex = /\?(.*?)\?|\$(.*?)\$|#(.*?)#|@(.*?)@/g
      const textMatches = this.rawText.matchAll((textRegex)).toArray()
      this.refList = textMatches.map((match) => match[0])

      const textArray = []
      let runningText = this.rawText

      if (this.refList.length === 0) {
        textArray.push(runningText)
      } else {
        for (let i = 0; i <= this.refList.length; i++) {
          const splitTextArray = runningText.split(`${this.refList[i]}`)
          textArray.push(splitTextArray[0])
          textArray.push(this.refList[i])
          runningText = splitTextArray.slice(1).join(`${this.refList[i]}`)
        }
      }

      return textArray
    },
    createTextContainerElements (createElement, preparedTextArray) {
      const elementsToRender = []

      if (this.filter) {
        elementsToRender.push(this.createFilterElement(createElement))
      }

      preparedTextArray.forEach((elementName) => {
        if (!elementName) {
          return
        }

        if (elementName.startsWith('?')) {
          elementsToRender.push(this.createInputElement(createElement, elementName))
          return
        }

        if (elementName.startsWith('$')) {
          elementsToRender.push(this.createDropdownElement(createElement, elementName))
          return
        }

        if (elementName.startsWith('#')) {
          elementsToRender.push(this.createMultiSelectElement(createElement, elementName))
          return
        }

        if (elementName.startsWith('@')) {
          elementsToRender.push(this.createFilteredElement(createElement, elementName))
          return
        }

        elementsToRender.push(createElement(
          'span', {
            domProps: {
              innerHTML: elementName
            }
          }
        ))
      })

      return createElement('div', {
        class: 'template-text-container'
      }, elementsToRender)
    },
    createInputElement (createElement, elementName) {
      return createElement('input', {
        class: 'input-container',
        ref: elementName,
        domProps: {
          value: this.inputValues[elementName]
        },
        attrs: {
          id: elementName,
          placeholder: elementName.length === 3 ? '??' : elementName.slice(1, -1) + '...'
        },
        props: {
          type: 'text'
        },
        on: {
          input: (event) => { this.inputValues[elementName] = event.target.value }
        }
      })
    },
    createDropdownElement (createElement, elementName) {
      const placeholderOptionElement = createElement('option', {
        class: 'option-placeholder option-container',
        attrs: {
          value: ''
        },
        domProps: {
          innerHTML: 'Select one...'
        }
      })

      const options = this.textOptions[elementName.slice(1, -1)].split(';')
      const optionsElements = options.map((option) => createElement('option', {
        class: 'option-container',
        domProps: {
          innerHTML: option
        }
      }))

      return createElement(
        'select',
        {
          class: 'select-container',
          ref: elementName,
          attrs: {
            id: elementName,
            ref: elementName
          },
          on: {
            input: (event) => {
              this.inputValues[elementName] = event.target.value
              this.$emit('input', event.target.value)
            }
          }
        },
        [placeholderOptionElement].concat(optionsElements)
      )
    },
    createMultiSelectElement (createElement, elementName) {
      const options = this.textOptions[elementName.slice(1, -1)].split(';')
      const items = options.map((option) => {
        return { text: option, value: option }
      })

      return createElement('v-autocomplete', {
        ref: elementName,
        props: {
          items: items,
          placeholder: elementName.slice(1, -1) + '...',
          filled: true,
          multiple: true,
          'small-chips': true,
          clearable: true,
          'item-value': 'value',
          'item-text': 'text'
        },
        on: {
          input: (event) => {
            this.inputValues[elementName] = event
          }
        }
      })
    },
    createFilterElement (createElement) {
      const filterLabel = createElement('div', {
        attrs: {
          small: true
        },
        domProps: {
          innerHTML: this.filter.filter_text
        }
      })

      const options = this.filter.filter_options.split(';')
      const items = options.map((option) => {
        return { text: option, value: option }
      })

      const filterInput = createElement('FilterAutocomplete', {
        ref: this.filter.filter_name,
        props: {
          id: this.filter.filter_name,
          items: items
        },
        on: {
          input: (event) => {
            this.inputValues[this.filter.filter_name] = event
            const filteredComponentName = this.filter.filter_name.replace('Filter', '')

            if (filteredComponentName === 'diagnosis') {
              this.$emit('update-filter-diagnosis', event)
            }

            this.$refs['@' + filteredComponentName + '@'].setItems(event.map((option) => {
              return { text: option, value: option }
            }))
          }
        }
      })
      return createElement('div', {
        class: `filter-container ${this.filter.hide_filter ? 'hidden' : ''}`
      }, [filterLabel, filterInput])
    },
    createFilteredElement (createElement, elementName) {
      return createElement('FilteredAutocomplete', {
        ref: elementName,
        on: {
          input: (event) => {
            this.inputValues[elementName] = event
          }
        }
      })
    },
    createAddButtonContainerElement (createElement) {
      const iconElement = createElement('v-icon', {
        attrs: {
          small: true
        },
        domProps: {
          innerHTML: 'fa-plus'
        }
      })

      return createElement('v-btn', {
        class: 'mx-1 add-btn',
        props: {
          small: true,
          depressed: true,
          fab: true,
          color: 'primary'
        },
        on: {
          click: this.getPreparedOutputText
        }
      }, [iconElement])
    },
    getPreparedOutputText () {
      let preparedOutputText = this.rawText
      this.refList.forEach((ref) => {
        if (ref.startsWith('#')) {
          const value = this.inputValues[ref] ? this.inputValues[ref].join(', ') : '??'
          preparedOutputText = preparedOutputText.replace(ref, value)
        }
        preparedOutputText = preparedOutputText.replace(ref, this.inputValues[ref] ? this.inputValues[ref] : '??')
      })
      preparedOutputText = preparedOutputText.replaceAll('<br>', '\n')
      if (preparedOutputText.slice(-1) !== '.') {
        preparedOutputText = preparedOutputText.concat('.')
      }
      this.$nextTick(() => {
        this.refList.forEach((ref) => {
          if (this.filter) {
            return
          }

          if (ref.startsWith('#')) {
            this.$refs[ref].reset()
            this.inputValues[ref] = null
            return
          }

          this.$refs[ref].value = null
          this.inputValues[ref] = null
        })
      })
      this.$emit('add-prepared-template', preparedOutputText
      )
    }
  },
  async created () {
    this.rawText = this.template.text.replaceAll('\n', '<br>')
    if (this.template.text_options) {
      this.textOptions = this.template.text_options
      if (this.textOptions.filter) {
        this.filter = this.textOptions.filter
      }
    }
  },
  mounted () {
    if (this.filteredDiagnoses.diagnoses && this.filter) {
      this.$refs[this.filter.filter_name].setValues(this.filteredDiagnoses.diagnoses)

      this.$refs['@diagnosis@'].setItems(this.filteredDiagnoses.diagnoses.map((option) => {
        return { text: option, value: option }
      }))
    }
  },
  render (createElement) {
    const preparedTextArray = this.prepareTextArray()
    const textElementsToRender = this.createTextContainerElements(createElement, preparedTextArray)
    const addButtonElementToRender = this.createAddButtonContainerElement(createElement)

    return createElement(
      'div',
      {
        class: 'template-container'
      },
      [textElementsToRender, addButtonElementToRender]
    )
  }
}
</script>
<style scoped>
.input-container {
  cursor: text;
  padding-left: 10px;
  padding-right: 5px;
  max-width: 150px;
  border-radius: 4px 4px 0px 0px;
  margin-top: 8px;
  background: rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid #043561;
  outline: none;
}
.input-container:hover {
  background: rgba(0, 0, 0, 0.12);
}
.input-container::placeholder {
  color: #adadb7;
}
.select-container {
  cursor: text;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 2px;
  margin-top: 2px;
  background: rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid #043561;
  outline: none;
  width: 100%;
}
.template-container {
  padding: 10px;
  padding-top: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.template-text-container {
  line-height: 2;
  width: 85%
}
.add-btn {
  transform: scale(80%)
}
.select-container {
  width: 100%;
}
.filter-container {
  border-radius: 5px;
  background-color: #eee;
  color: black;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  margin-bottom: 5px;
}
.filter-container.hidden {
  display: none;
}
</style>
