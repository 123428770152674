import Dashboard from '../views/Dashboard'
import Auth from '@/router/middleware/auth'
import RoleCheck from '@/router/middleware/roleCheck'
import { Roles } from '@/js/Roles'

export default [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: 'Telescope | Dashboard'
    }
  },
  // clinic admin/hub routes
  {
    path: '/case/create',
    name: 'CreateCase',
    component: () => import(/* webpackChunkName: "CreatePatient" */ '../views/case/Create.vue'),
    meta: {
      title: 'Create Case',
      middleware: [Auth, RoleCheck],
      roles: [Roles.ADMIN, Roles.BASIC, Roles.CLINIC_STAFF]
    }
  },
  {
    path: '/cases/intakes',
    name: 'IntakesIndex',
    component: () => import(/* webpackChunkName: "IntakesIndex" */ '../views/case/IntakesIndex.vue'),
    meta: {
      title: 'Intake Cases',
      middleware: [Auth, RoleCheck],
      roles: [Roles.ADMIN, Roles.BASIC, Roles.CLINIC_STAFF]
    }
  },
  {
    path: '/cases/compliance',
    name: 'ComplianceCases',
    component: () => import(/* webpackChunkName: "Compliance" */ '../views/case/Compliance.vue'),
    meta: {
      title: 'Intake Cases',
      middleware: [Auth, RoleCheck],
      roles: [Roles.ADMIN, Roles.BASIC]
    }
  },
  {
    path: '/cases/active',
    name: 'ActiveCases',
    component: () => import(/* webpackChunkName: "ActiveCases" */ '../views/case/Active.vue'),
    meta: {
      title: 'Active Cases',
      middleware: [Auth, RoleCheck],
      roles: [Roles.ADMIN, Roles.BASIC, Roles.CLINIC_STAFF]
    }
  },
  {
    path: '/cases/billing',
    name: 'BillsAndRecords',
    component: () => import(/* webpackChunkName: "Bills" */ '../views/case/Bills.vue'),
    meta: {
      title: 'Bills And Records',
      middleware: [Auth, RoleCheck],
      roles: [Roles.ADMIN, Roles.BASIC]
    }
  },
  {
    path: '/cases/accounts-receivable',
    name: 'AccountsReceivable',
    component: () => import(/* webpackChunkName: "Bills" */ '../views/case/AccountsReceivable.vue'),
    meta: {
      title: 'Bills And Records',
      middleware: [Auth, RoleCheck],
      roles: [Roles.ADMIN, Roles.BASIC]
    }
  },
  {
    path: '/cases/polaris',
    name: 'ReferredCases',
    component: () => import(/* webpackChunkName: "Referrals" */ '../views/case/Referrals.vue'),
    meta: {
      title: 'Polaris',
      middleware: [Auth, RoleCheck],
      roles: [Roles.ADMIN, Roles.BASIC]
    }
  },
  {
    path: '/case/:caseId',
    name: 'CaseView',
    props: true,
    component: () => import(/* webpackChunkName: "ActiveCases" */ '../views/case/CaseView.vue'),
    meta: {
      title: 'Active Cases',
      middleware: [Auth, RoleCheck],
      roles: [Roles.ADMIN, Roles.BASIC, Roles.CLINIC_STAFF]
    }
  },
  {
    path: '/case/:caseId/intakes/:uuid',
    name: 'ClinicIntakeView',
    props: true,
    component: () => import(/* webpackChunkName: "IntakeView" */ '../views/case/ClinicIntakeView'),
    meta: {
      title: 'View Intake',
      roles: [Roles.ADMIN, Roles.BASIC, Roles.CLINIC_STAFF]
    }
  },
  {
    path: '/provider-schedules',
    name: 'ProviderSchedulesView',
    props: true,
    component: () => import(/* webpackChunkName: "IntakeView" */ '../views/provider/ProviderSchedules.vue'),
    meta: {
      title: 'Provider Schedules',
      roles: [Roles.ADMIN, Roles.BASIC, Roles.CLINIC_STAFF, Roles.PROVIDER]
    }
  },
  {
    path: '/provider/patient/:patientId',
    name: 'PatientChartEditView',
    props: true,
    component: () => import(/* webpackChunkName: "PatientChartView" */ '../views/provider/PatientChartEditView.vue'),
    meta: {
      title: 'Patient Chart',
      middleware: [Auth, RoleCheck],
      roles: [Roles.ADMIN, Roles.BASIC, Roles.CLINIC_STAFF, Roles.PROVIDER]
    }
  },
  {
    path: '/provider/patient/:patientId/provider-encounter/:encounterId',
    name: 'ProviderEncounterView',
    props: true,
    component: () => import(/* webpackChunkName: "PatientChartView" */ '../views/provider/ProviderEncounterView.vue'),
    meta: {
      title: 'Provider Encounter',
      middleware: [Auth, RoleCheck],
      roles: [Roles.PROVIDER, Roles.ADMIN, Roles.BASIC, Roles.CLINIC_STAFF, Roles.PROVIDER]
    }
  }
]
