
export const TaskRequiredActions = {
  ACKNOWLEDGE: 'acknowledge',
  COMPLETION: 'completion'
}

export const TaskRequiredActionOptions = [
  TaskRequiredActions.ACKNOWLEDGE,
  TaskRequiredActions.COMPLETION
]
