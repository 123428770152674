var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CreateTelephoneEncounterDialog',{ref:"createTelephoneEncounter",on:{"encounterChange":function($event){return _vm.$emit('encounterChange')}}}),_c('UpdateTelephoneEncounterDialog',{ref:"updateTelephoneEncounter",on:{"encounterChange":function($event){return _vm.$emit('encounterChange')}}}),_c('div',[_c('v-row',[_c('v-col',[_c('h3',[_vm._v("Encounters")])]),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.$refs.createTelephoneEncounter.open()}}},[_vm._v("Telephone Encounter")])],1)],1),_c('v-data-table',{staticClass:"mt-4",attrs:{"items":_vm.encounters,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.encounter_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type === _vm.EncounterTypes.TELEPHONE_ENCOUNTER ? _vm.formatCarbonDateNoSetTime(item.encounter_date) : _vm.formatCarbonDateNoSetTime(item.appointment.date))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type === _vm.EncounterTypes.TELEPHONE_ENCOUNTER ? 'Telephone Encounter' : item.appointment.visit_type)+" ")]}},{key:"item.providers",fn:function(ref){
var item = ref.item;
return [(item.type === _vm.EncounterTypes.TELEPHONE_ENCOUNTER)?_c('td',_vm._l((item.providers),function(provider,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(provider.provider_name)+_vm._s(index === item.providers.length - 1 ? '' : ', ')+" ")])}),0):_c('td',[_c('span',[_vm._v(_vm._s(item.provider.provider_name))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fas fa-ellipsis-h")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticStyle:{"cursor":"pointer","user-select":"none"},on:{"click":function($event){return _vm.editEncounter(item)}}},[_vm._v(" View Encounter ")])],1),(_vm.showAddAddendum(item))?_c('v-list-item',[_c('v-list-item-title',[_vm._v("Add Addendum")])],1):_vm._e(),(_vm.showDownloadEncounter(item))?_c('v-list-item',[_c('v-list-item-title',[_vm._v("Download Encounter")])],1):_vm._e()],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }