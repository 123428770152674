export const VisitTypes = {
  NP_NEW_EVAL: 'NP New Eval',
  FOLLOW_UP: 'Follow-Up',
  PT_NEW_EVAL: 'PT New Eval',
  PT_RE_EVAL: 'PT Re-Eval',
  PTA_TX: 'PTA Tx',
  TELEHEALTH_NP_NEW_EVAL: 'Telehealth NP New Eval',
  TELEHEALTH_FOLLOW_UP: 'Telehealth Follow-Up',
  TELEHEALTH_PT_NEW_EVAL: 'Telehealth PT New Eval',
  TELEHEALTH_PT_RE_EVAL: 'Telehealth PT Re-Eval',
  TELEHEALTH_PTA_TX: 'Telehealth PTA Tx',
  PROCEDURES: 'Procedures (CESI, LESI, CMBB, LMBB, C Ablation, L Ablation, Joint Injection)',
  SUP_VISIT: 'Sup Visit'
}

export const VisitTypeOptions = [
  { text: VisitTypes.NP_NEW_EVAL, value: VisitTypes.NP_NEW_EVAL, duration: '30', color: 'blue darken-1' },
  { text: VisitTypes.FOLLOW_UP, value: VisitTypes.FOLLOW_UP, duration: '15', color: 'orange darken-1' },
  { text: VisitTypes.PT_NEW_EVAL, value: VisitTypes.PT_NEW_EVAL, duration: '30', color: 'green darken-1' },
  { text: VisitTypes.PT_RE_EVAL, value: VisitTypes.PT_RE_EVAL, duration: '30', color: 'pink darken-1' },
  { text: VisitTypes.PTA_TX, value: VisitTypes.PTA_TX, duration: '30', color: 'purple darken-1' },
  { text: VisitTypes.TELEHEALTH_NP_NEW_EVAL, value: VisitTypes.TELEHEALTH_NP_NEW_EVAL, duration: '30', color: 'blue darken-1' },
  { text: VisitTypes.TELEHEALTH_FOLLOW_UP, value: VisitTypes.TELEHEALTH_FOLLOW_UP, duration: '15', color: 'orange darken-1' },
  { text: VisitTypes.TELEHEALTH_PT_NEW_EVAL, value: VisitTypes.TELEHEALTH_PT_NEW_EVAL, duration: '30', color: 'green darken-1' },
  { text: VisitTypes.TELEHEALTH_PT_RE_EVAL, value: VisitTypes.TELEHEALTH_PT_RE_EVAL, duration: '30', color: 'pink darken-1' },
  { text: VisitTypes.TELEHEALTH_PTA_TX, value: VisitTypes.TELEHEALTH_PTA_TX, duration: '30', color: 'purple darken-1' },
  { text: VisitTypes.PROCEDURES, value: VisitTypes.PROCEDURES, duration: '15', color: 'teal darken-1' },
  { text: VisitTypes.SUP_VISIT, value: VisitTypes.SUP_VISIT, duration: '15', color: 'brown darken-1' }
]

export const VisitStatuses = {
  PENDING: 'Pending',
  CHECK_IN: 'Check-In',
  CHECK_OUT: 'Check-Out',
  STAFF_CHANGE: 'Staff Change',
  R_S: 'R/S',
  N_S: 'N/S',
  CANCEL: 'Cancel'
}

export const VisitStatusOptions = [
  { text: VisitStatuses.PENDING, value: VisitStatuses.PENDING },
  { text: VisitStatuses.CHECK_IN, value: VisitStatuses.CHECK_IN },
  { text: VisitStatuses.CHECK_OUT, value: VisitStatuses.CHECK_OUT },
  { text: VisitStatuses.STAFF_CHANGE, value: VisitStatuses.STAFF_CHANGE },
  { text: 'Reschedule', value: VisitStatuses.R_S },
  { text: 'No Show', value: VisitStatuses.N_S },
  { text: VisitStatuses.CANCEL, value: VisitStatuses.CANCEL }
]

export const PatientLogActions = {
  PATIENT_NOTE: 'Patient Note'
}

export const DurationOptions = [
  { text: '15 mins', value: '15' },
  { text: '30 mins', value: '30' }
]
