<template>
  <div>
<!-- Dialogs   -->
    <CreateTelephoneEncounterDialog ref="createTelephoneEncounter" @encounterChange="$emit('encounterChange')" />
    <UpdateTelephoneEncounterDialog ref="updateTelephoneEncounter" @encounterChange="$emit('encounterChange')" />
    <div>
      <v-row>
        <v-col>
          <h3>Encounters</h3>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn small color="primary" @click="$refs.createTelephoneEncounter.open()">Telephone Encounter</v-btn>
        </v-col>
      </v-row>

<!--  Encounters -->
      <v-data-table
        :items="encounters"
        :headers="headers"
        class="mt-4"
      >
        <template #item.encounter_date="{ item }">
          {{item.type === EncounterTypes.TELEPHONE_ENCOUNTER ? formatCarbonDateNoSetTime(item.encounter_date) : formatCarbonDateNoSetTime(item.appointment.date)}}
        </template>
        <template #item.type="{ item }">
          {{item.type === EncounterTypes.TELEPHONE_ENCOUNTER ? 'Telephone Encounter' : item.appointment.visit_type}}
        </template>
        <template #item.providers="{ item }">
          <td v-if="item.type === EncounterTypes.TELEPHONE_ENCOUNTER">
            <span v-for="(provider, index) in item.providers" :key="index">
              {{provider.provider_name}}{{index === item.providers.length - 1 ? '' : ', '}}
            </span>
          </td>
          <td v-else>
            <span>{{ item.provider.provider_name }}</span>
          </td>
        </template>
        <template #item.actions="{ item }">
          <v-menu>
            <template #activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>fas fa-ellipsis-h</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title style="cursor: pointer; user-select: none;" @click="editEncounter(item)">
                  View Encounter
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="showAddAddendum(item)">
                <v-list-item-title>Add Addendum</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="showDownloadEncounter(item)">
                <v-list-item-title>Download Encounter</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CreateTelephoneEncounterDialog from '@/components/Provider/Dialogs/CreateTelephoneEncounterDialog.vue'
import UpdateTelephoneEncounterDialog from '@/components/Provider/Dialogs/UpdateTelephoneEncounterDialog.vue'
import { EncounterTypes } from '@/js/Provider/TelephoneEncounter'
import { formatCarbonDateNoSetTime } from '@/js/PatientIntake/functions'
import { Roles } from '@/js/Roles'
import event, { Events } from '@/event'

export default {
  components: { CreateTelephoneEncounterDialog, UpdateTelephoneEncounterDialog },
  data: () => ({
    Roles,
    EncounterTypes,
    headers: [
      { text: 'Encounter Date', value: 'encounter_date' },
      { text: 'Visit Type', value: 'type' },
      { text: 'Providers', value: 'providers' },
      { text: 'Quick Actions', value: 'actions' }
    ]
  }),
  computed: {
    ...mapGetters({
      patient: 'ProviderPatient/patientDemographics',
      encounters: 'ProviderPatient/patientEncounters',
      hasAccess: 'User/hasAccess',
      userId: 'User/getId',
      isProvider: 'User/isProvider',
      providerId: 'User/getProviderId'
    })
  },
  methods: {
    formatCarbonDateNoSetTime,
    showAddAddendum (encounter) {
      if (encounter.type === EncounterTypes.TELEPHONE_ENCOUNTER) {
        return false
      }
      return false
    },
    showDownloadEncounter (encounter) {
      return false
    },
    async editEncounter (encounter) {
      if (encounter.type === EncounterTypes.TELEPHONE_ENCOUNTER) {
        encounter.encounter_date = formatCarbonDateNoSetTime(encounter.encounter_date)
        this.$refs.updateTelephoneEncounter.open(encounter)
        return
      }

      if (this.$route.params.encounterId && this.$route.params.encounterId === encounter.id.toString()) {
        event.emit(Events.ERROR, 'Error: Currently on provider encounter', 2000)
        return
      }

      // TO handle when on the provider encounter change and routing to a different encounter
      await this.$store.dispatch('ProviderPatient/getProviderEncounter', {
        patientId: this.patient.id,
        encounterId: encounter.id
      })

      this.$emit('routeEncounter')

      await this.$router.push({
        name: 'ProviderEncounterView',
        params: {
          patientId: this.patient.id.toString(),
          encounterId: encounter.id.toString()
        }
      })
    }
  }
}
</script>
