<template>
  <v-dialog v-model="dialog" max-width="600" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title class="dialog-banner">Add Alert</v-card-title>
      <v-card-text class="mt-6">
        <v-form>
          <div>
            <v-checkbox
              v-model="new_alerts"
              :label="PatientAlerts.HOLD_PROVIDER"
              :value="PatientAlerts.HOLD_PROVIDER"
              v-show="!providerAlert"
            />
            <v-checkbox
              v-if="hasAccess([Roles.ADMIN, Roles.BASIC])"
              v-model="new_alerts"
              :label="PatientAlerts.HOLD_ATTORNEY"
              :value="PatientAlerts.HOLD_ATTORNEY"
              v-show="!attorneyAlert"
            />
            <div class="d-flex" v-if="hasAccess([Roles.ADMIN, Roles.BASIC])">
              <v-checkbox v-model="custom_alert_checkbox" class="mt-3" :disabled="true"/>
              <v-text-field
                v-model="custom_alert"
                label="Custom Alert"
                filled dense clearable
                hide-details
                @input="checkCustomAlert"
              />
            </div>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn text @click="cancel">Cancel</v-btn>
        <v-btn depressed color="primary" @click="createAlert" :loading="loading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import RulesMixin from '@/mixins/RulesMixin'
import event, { Events } from '@/event'
import { PatientAlerts } from '@/js/Provider/Alert'
import { mapGetters } from 'vuex'
import { Roles } from '@/js/Roles'

export default {
  name: 'CreateAlertDialog',
  mixins: [RulesMixin],
  components: {},
  data: () => ({
    PatientAlerts,
    Roles,
    dialog: false,
    new_alerts: [],
    custom_alert_checkbox: false,
    custom_alert: '',
    available_alerts: [],
    loading: false
  }),
  computed: {
    ...mapGetters({
      url: 'Organization/apiUrl',
      patient: 'ProviderPatient/patientDemographics',
      alerts: 'ProviderPatient/patientAlerts',
      hasAccess: 'User/hasAccess'
    }),
    providerAlert () {
      return !!this.alerts.find((alert) => alert.alert === PatientAlerts.HOLD_PROVIDER)
    },
    attorneyAlert () {
      return !!this.alerts.find((alert) => alert.alert === PatientAlerts.HOLD_ATTORNEY)
    }
  },
  methods: {
    open () {
      this.dialog = true
    },
    cancel () {
      this.reset()
    },
    reset () {
      this.new_alerts = []
      this.custom_alert = ''
      this.custom_alert_checkbox = false
      this.dialog = false
    },
    async createAlert () {
      this.loading = true
      try {
        await window.axios.post(this.$store.getters['Organization/apiUrl'] + '/provider/patients/' + this.patient.id + '/alerts', {
          alerts: this.new_alerts,
          custom_alert: this.custom_alert
        })
        // update the users tasks
        await this.$store.dispatch('User/updateUserTasks')
      } catch (error) {
        event.emit(Events.ERROR, error.response.data.payload.message, 400)
      }

      this.$emit('addedAlert')
      this.loading = false
      this.reset()
    },
    checkCustomAlert () {
      if (!this.custom_alert) {
        this.custom_alert_checkbox = false
        return
      }
      this.custom_alert_checkbox = true
    }
  }
}
</script>
<style scoped>
.dialog-banner {
  background-color: #043561;
  color: #ffffff;
}
</style>
