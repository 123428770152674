<template>
  <div>
<!-- Dialogs   -->
    <CreateAlertDialog ref="createAlertDialog" @addedAlert="$emit('alertChange')"/>
    <RemoveAlertDialog ref="removeAlertDialog" @removedAlert="$emit('alertChange')"/>
    <div class="mt-8">
      <v-row>
        <v-col>
          <h3>Alerts</h3>
        </v-col>
        <v-col class="d-flex justify-end" v-if="hasAccess([Roles.ADMIN, Roles.BASIC, Roles.PROVIDER])">
          <v-btn
            small
            color="primary"
            @click="$refs.createAlertDialog.open(patient.id)"
            :disabled="disableAddAlert"
          >Add Alert</v-btn>
        </v-col>
      </v-row>
      <div class="mt-6">
        <v-alert v-if="alerts.length === 0" dense text type="success">No Pending Alerts</v-alert>
        <div v-for="(alert, index ) in alerts" :key="index">
          <v-alert dense text type="error">
            <v-row>
              <v-col>
                {{alert.alert}}
              </v-col>
              <v-col class="d-flex justify-end">
                <v-btn
                  v-if="hasAccess([Roles.ADMIN, Roles.BASIC]) || (hasAccess([Roles.PROVIDER]) && alert.user_id === userId)"
                  small
                  color="error"
                  @click="$refs.removeAlertDialog.open(alert.id)"
                >Remove</v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { Roles } from '@/js/Roles'
import CreateAlertDialog from '@/components/Provider/Dialogs/CreateAlertDialog.vue'
import RemoveAlertDialog from '@/components/Provider/Dialogs/RemoveAlertDialog.vue'
import { PatientAlerts } from '@/js/Provider/Alert'

/**
 * RemoveAlertDialog is used in components where the patient is known ie Patient Chart, Appointment Dialog
 * A separate remove dialog will need to be added for quick actions to select which alert to remove
 * CreateAlert may need to be modified when added to quick actions
 */
export default {
  components: { CreateAlertDialog, RemoveAlertDialog },
  data: () => ({
    Roles
  }),
  computed: {
    ...mapGetters({
      url: 'Organization/apiUrl',
      hasAccess: 'User/hasAccess',
      patient: 'ProviderPatient/patientDemographics',
      alerts: 'ProviderPatient/patientAlerts',
      userId: 'User/getId'
    }),
    disableAddAlert () {
      if (this.hasAccess([Roles.ADMIN, Roles.BASIC])) {
        return false
      }

      if (this.hasAccess([Roles.PROVIDER]) && !this.providerAlertActive) {
        return false
      }

      return true
    },
    providerAlertActive () {
      return !!this.alerts.find((alert) => alert.alert === PatientAlerts.HOLD_PROVIDER)
    }
  },
  methods: {}
}
</script>
