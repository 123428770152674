// expects date format YYYY-MM-DD and time HH:MM AM/PM
export function parseDate (date, time) {
  const dateString = date + ' ' + time

  const [datePart, timePart, ampm] = dateString.split(' ')
  const [year, month, day] = datePart.split('-').map(Number)
  let [hours, minutes] = timePart.split(':').map(Number)

  if (ampm.toUpperCase() === 'PM' && hours !== 12) {
    hours += 12
  } else if (ampm.toUpperCase() === 'AM' && hours === 12) {
    hours = 0
  }

  return new Date(year, month - 1, day, hours, minutes)
}
// ranges are an array of two date objects (start datetime, end datetime)
export function dateTimesOverlap (range1, range2) {
  if (range1[0] < range2[1] && range2[0] < range1[1]) {
    return true
  }

  return false
}

// expects date format YYYY-MM-DD
export function convertToPrettyDate (date) {
  const [year, month, day] = date.split('-')
  return `${month}/${day}/${year}`
}
