<template>
  <v-dialog @click:outside="close" v-model="show" max-width="600" max-height="350" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card class="text-center">
      <v-card-title class="primary white--text">Restore User</v-card-title>
      <v-card-text>
        <v-form ref="restoreForm" v-model="formIsValid">
          <v-select
            label="Select a role to restore to the user"
            v-model="selectedRole"
            :items="availableRoles"
            filled
            dense
            class="mt-5"
            :rules="[rules.required]"
          />
          <v-row style="max-height:150px" v-if="selectedRole === Roles.PROVIDER">
            <v-col cols="6">
              <v-autocomplete
                label="Location"
                v-model="selectedProviderLocations"
                :items="updatedProviderLocations"
                item-text="name"
                item-value="id"
                class="my-6"
                multiple
                small-chips
                filled
                dense
                clearable
                :rules="[rules.requiredMultiSelect]"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                label="Provider Type"
                v-model="selectedProviderType"
                :items="providerTypes"
                class="my-6"
                filled
                persistent-hint
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="close">Cancel</v-btn>
        <v-btn depressed color="primary" @click="restoreAccess">Restore</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import event, { Events } from '@/event'
import { RoleOptions, Roles } from '@/js/Roles'
import { ProviderOptions } from '@/js/ProviderTypes'
import { mapGetters, mapState } from 'vuex'
import RulesMixin from '@/mixins/RulesMixin'

export default {
  name: 'RestoreUserDialog',
  mixins: [RulesMixin],
  data: () => ({
    Roles,
    show: false,
    userId: '',
    selectedRole: Roles.BASIC,
    availableRoles: RoleOptions.filter(role => role.value !== Roles.INDIVIDUAL && role.value !== Roles.NO_ACCESS),
    selectedProviderLocations: [],
    updatedProviderLocations: [],
    selectedProviderType: '',
    providerTypes: ProviderOptions,
    showRestoreAccessDialog: false,
    formIsValid: true
  }),
  computed: {
    ...mapState({
      providerLocations: state => state.Organization.locations
    }),
    ...mapGetters({
      url: 'Organization/apiUrl'
    })
  },
  methods: {
    open (item) {
      this.updateProviderLocations()
      this.userId = item.id
      this.show = true
    },
    close () {
      this.userId = ''
      this.selectedRole = Roles.BASIC
      this.selectedProviderLocations = []
      this.selectedProviderType = ''
      this.show = false
    },
    updateProviderLocations () {
      this.updatedProviderLocations = this.providerLocations.filter((location) => location.id !== 'all')
    },
    restoreAccess () {
      this.$refs.restoreForm.validate()

      if (!this.formIsValid) {
        return
      }

      event.emit(Events.CONFIRM, `Are you sure you want to restore access to this user and assign them a role of "${this.selectedRole}"?`, () => {
        event.emit(Events.LOADING, true)

        const payload = {
          role: this.selectedRole
        }

        if (this.selectedRole === Roles.PROVIDER) {
          payload.location_ids = this.selectedProviderLocations
          payload.provider_type = this.selectedProviderType
        }

        this.$axios
          .put(`${this.url}/user/${this.userId}`, payload)
          .then(() => {
            this.$store.dispatch('Organization/getUsers')
            this.close()
            event.emit(Events.SUCCESS, 'User access restored!')
            event.emit(Events.LOADING, false)
          })
          .catch(() => {
            this.close()
            event.emit(Events.LOADING, false)
          })
      }, { ok: 'Yes, restore this user', cancel: 'Cancel', title: 'Restore Access?' })
    }
  }
}
</script>
