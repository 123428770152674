import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import Case from './modules/Case'
import Organization from './modules/Organization'
import User from './modules/User'
import Treatments from './modules/Treatments'
import PatientIntake from './modules/PatientIntake'
import ProviderPatient from './modules/ProviderPatient'

Vue.use(Vuex)
const vuexSession = new VuexPersistence({
  storage: window.sessionStorage
})
export default new Vuex.Store({
  state: {
    loading: false
  },
  mutations: {
    loading (state, loadingStatus) {
      state.loading = loadingStatus
    }
  },
  actions: {},
  modules: {
    Case,
    Organization,
    User,
    Treatments,
    PatientIntake,
    ProviderPatient
  },
  plugins: [vuexSession.plugin]
})
