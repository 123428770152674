<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    @click:outside="cancel"
  >
    <v-card>
      <v-card-title class="primary white--text">{{isCreateTimeBlock ? 'Create' : 'Update'}} Provider Time Block</v-card-title>
      <v-card-text class="pt-3">
        <v-form ref="form" v-model="formValid">
          <h3 class="mt-2">Select a time range:</h3>
          <v-row>
            <v-col cols="4">
              <time-picker
                ref="startTimepicker"
                :time="time_block.start_time"
                placeholder="Start Time"
                :timeValid="startTimeValid"
                @blur="validateStartTime"
                @time-change="setStartTime"
                :disabled="isAllDay"
              />
            </v-col>
            <v-col cols="4">
              <time-picker
                ref="endTimepicker"
                :time="time_block.end_time"
                placeholder="End Time"
                :timeValid="endTimeValid"
                @blur="validateEndTime"
                @time-change="setEndTime"
                :disabled="isAllDay"
              />
            </v-col>
            <v-col cols="4">
              <v-switch
                v-model="isAllDay"
                label="All Day"
                @change="changeIsAllDay"
              ></v-switch>
            </v-col>
          </v-row>
          <h3>Schedule a date:</h3>
          <v-row>
            <v-col cols="4">
              <date-picker
                ref="startDatepicker"
                :date="time_block.start_date"
                :default-to-today="false"
                @date-change="setStartDate"
                :dateValid="startDateValid"
                placeholder="Start Date"
                @blur="validateStartDate"
              />
            </v-col>
            <v-col cols="4">
              <v-switch
                v-model="isOngoing"
                label="Multiple Days"
                @change="checkIsOngoing"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row v-if="isOngoing">
            <v-col cols="4">
              <date-picker
                ref="endDatepicker"
                :date="time_block.end_date"
                :default-to-today="false"
                @date-change="setEndDate"
                :dateValid="endDateValid"
                placeholder="End Date"
                @blur="validateEndDate"
              />
            </v-col>
            <v-col cols="8">
              <v-autocomplete
                v-model="time_block.days_of_week"
                label="Days Of The Week"
                :items="daysOfWeekOptions"
                placeholder="Select days of the week"
                item-value="value"
                item-text="text"
                filled
                multiple
                small-chips
                clearable
                :rules="[rules.requiredMultiSelect]"
                @change="checkForAllDays"
              >
                <template v-slot:item="{ on, item }">
                  <v-list-item v-on="on" :disabled="disableDay(item)">
                    <v-checkbox :input-value="time_block.days_of_week.includes(item.value)" :disabled="disableDay(item)"></v-checkbox>
                    {{ item.text }}</v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
<!--          <h3>Add a note (optional):</h3>-->
<!--          <v-row class="mt-1 px-2">-->
<!--            <v-textarea-->
<!--              v-model="time_block.notes"-->
<!--              rows="4"-->
<!--              filled-->
<!--              dense-->
<!--              placeholder="Notes..."-->
<!--            />-->
<!--          </v-row>-->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancel">Close</v-btn>
        <v-btn class="primary" @click="saveTimeBlock">{{isCreateTimeBlock ? 'Create' : 'Update'}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import RulesMixin from '@/mixins/RulesMixin'
import TimePicker from '@/components/TimePicker.vue'
import DatePicker from '@/components/PatientIntake/DatePicker.vue'
import event, { Events } from '@/event'
import { dateTimesOverlap } from '@/js/Provider/ProviderScheduleDateTimeFunctions'

export default {
  name: 'ManageProviderTimeBlocksDialog',
  components: {
    DatePicker,
    TimePicker
  },
  mixins: [RulesMixin],
  props: ['calendarEvents', 'selectedLocationId', 'selectedProviderId'],
  data: () => ({
    dateTimesOverlap,
    dialog: false,
    startDateValid: true,
    endDateValid: true,
    formValid: true,
    formComplete: true,
    startTimeValid: true,
    endTimeValid: true,
    daysOfWeekOptions: [
      { text: 'All', value: 'All' },
      { text: 'Monday', value: 'Monday' },
      { text: 'Tuesday', value: 'Tuesday' },
      { text: 'Wednesday', value: 'Wednesday' },
      { text: 'Thursday', value: 'Thursday' },
      { text: 'Friday', value: 'Friday' }
    ],
    time_block: {
      location_id: null,
      provider_id: null,
      start_date: '',
      end_date: null,
      start_time: '',
      end_time: '',
      days_of_week: ['All'],
      notes: ''
    },
    isAllDay: false,
    isOngoing: false,
    isCreateTimeBlock: true,
    dateTimeIsAvailable: true
  }),
  methods: {
    open (item) {
      // Create Time Block
      if (item === undefined) {
        this.time_block.location_id = this.selectedLocationId
        this.time_block.provider_id = this.selectedProviderId
        this.dialog = true
        return
      }

      // Update TIme Block
      this.time_block = item
      this.isCreateTimeBlock = false

      if (item.start_time === '8:00 AM' && item.end_time === '5:00 PM') {
        this.isAllDay = true
      }

      if (item.end_date) {
        this.isOngoing = true
        this.time_block.days_of_week = item.days_of_week.split(',')
      }

      this.dialog = true
    },
    cancel () {
      this.$emit('fetch-time-blocks')
      this.dialog = false
      this.reset()
    },
    changeIsAllDay () {
      if (this.isAllDay) {
        this.time_block.start_time = '8:00 AM'
        this.time_block.end_time = '5:00 PM'
        this.$nextTick(() => {
          this.$refs.startTimepicker.checkTime()
          this.$refs.endTimepicker.checkTime()
        })
        return
      }

      this.time_block.start_time = ''
      this.time_block.end_time = ''
      this.$nextTick(() => {
        this.$refs.startTimepicker.checkTime()
        this.$refs.endTimepicker.checkTime()
      })
    },
    reset () {
      this.time_block = {
        start_date: '',
        end_date: null,
        start_time: '',
        end_time: '',
        days_of_week: ['All'],
        provider_id: null,
        notes: ''
      }
      this.$refs.form.reset()
      this.dateValid = true
      this.startTimeValid = true
      this.endTimeValid = true
      this.formValid = true
      this.isAllDay = false
      this.$refs.form.reset()
      this.dateTimeIsAvailable = true
      this.formComplete = true
      this.isCreateTimeBlock = true
      this.$nextTick(() => {
        this.$refs.startDatepicker.clearInput()
        this.$refs.startDatepicker.checkDate()
        this.$refs.startTimepicker.clearInput()
        this.$refs.endTimepicker.clearInput()
        this.$refs.startTimepicker.checkTime()
        this.$refs.endTimepicker.checkTime()

        if (this.isOngoing) {
          this.$refs.endDatepicker.clearInput()
          this.$refs.endDatepicker.checkDate()
          this.isOngoing = false
        }
      })
    },
    setStartDate (value) {
      this.time_block.start_date = value
    },
    setEndDate (value) {
      this.time_block.end_date = value
    },
    setStartTime (value) {
      this.time_block.start_time = value.time + ' ' + value.day
      this.validateEndTime()
    },
    setEndTime (value) {
      this.time_block.end_time = value.time + ' ' + value.day
      this.validateEndTime()
    },
    validateStartDate () {
      this.startDateValid = true
      if (!this.time_block.start_date) {
        this.startDateValid = 'Required'
      }
    },
    validateEndDate () {
      this.endDateValid = true
      if (!this.time_block.end_date) {
        this.endDateValid = 'Required'
      }
    },
    validateStartTime () {
      this.startTimeValid = true
      if (!this.time_block.start_time) {
        this.startTimeValid = 'Required'
      }
    },
    validateEndTime () {
      this.endTimeValid = true
      if (!this.time_block.end_time) {
        this.endTimeValid = 'Required'
      }

      if (!this.isAllDay && this.time_block.start_time && this.time_block.end_time) {
        const startTime = new Date(`1/1/2000 ${this.time_block.start_time}`)
        const endTime = new Date(`1/1/2000 ${this.time_block.end_time}`)

        if (startTime >= endTime) {
          this.endTimeValid = 'End time must be greater than start time'
          this.$nextTick(() => {
            this.$refs.endTimepicker.clearInput()
          })
        }
      }
    },
    validateForm () {
      this.formComplete = true
      this.startTimeValid = true
      this.endTimeValid = true
      this.endDateValid = true
      this.$refs.form.validate()

      if (!this.isAllDay) {
        this.validateStartTime()
        this.validateEndTime()
      }

      this.validateStartDate()

      if (this.isOngoing) {
        this.validateEndDate()
      }

      this.formComplete = this.formValid &&
        this.startTimeValid !== 'Required' &&
        this.endTimeValid !== 'Required' &&
        this.startDateValid !== 'Required' &&
        this.endDateValid !== 'Required'
    },
    validateDateTimeAvailability () {
      this.dateTimeIsAvailable = true

      const currentTimeBlockSimulatedEvents = this.simulateTimeBlockEvents()

      const activeAppointments = this.calendarEvents.filter((event) =>
        event.isTimeBlock === false &&
        event.status !== 'Cancel' &&
        event.status !== 'Reschedule' &&
        event.status !== 'No Show' &&
        event.fullAppointmentData.provider_id === this.time_block.provider_id
      )

      currentTimeBlockSimulatedEvents.forEach((timeBlockEvent) => {
        const currentTimeBlockStart = timeBlockEvent.start
        const currentTimeBlockEnd = timeBlockEvent.end

        const currentTimeBlockDateRange = [currentTimeBlockStart, currentTimeBlockEnd]

        activeAppointments.forEach((appointment) => {
          const appointmentDateRange = [appointment.start, appointment.end]
          if (this.dateTimesOverlap(appointmentDateRange, currentTimeBlockDateRange)) {
            this.dateTimeIsAvailable = false
          }
        })
      })
    },
    simulateTimeBlockEvents () {
      const events = []

      if (this.time_block.end_date && !this.time_block.days_of_week.includes('All')) {
        const daysToAdd = this.getDatesByRangeWithMatchingWeekdays(this.time_block.start_date, this.time_block.end_date, this.time_block.days_of_week)
        for (const day of daysToAdd) {
          const startDateTime = new Date(`${day} ${this.time_block.start_time}`)
          const endDateTime = new Date(`${day} ${this.time_block.end_time}`)

          events.push({
            name: 'Provider Time Block',
            start: startDateTime,
            end: endDateTime,
            timed: true,
            fullTimeBlockData: this.time_block,
            isTimeBlock: true
          })
        }
        return events
      }

      const startDateTime = new Date(`${this.time_block.start_date} ${this.time_block.start_time}`)
      const endDateTime = new Date(`${this.time_block.end_date ? this.time_block.end_date : this.time_block.start_date} ${this.time_block.end_time}`)

      events.push({
        name: 'Provider Time Block',
        start: startDateTime,
        end: endDateTime,
        timed: true,
        fullTimeBlockData: this.time_block,
        isTimeBlock: true
      })
      return events
    },
    getDatesByRangeWithMatchingWeekdays (prettyStartDate, prettyEndDate, weekdaysToMatch) {
      const matchingDatesArray = []
      const currentDate = new Date(prettyStartDate + 'T12:00:00')
      const endDate = new Date(prettyEndDate + 'T12:00:00')

      const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

      const diffDays = this.daysBetweenDates(currentDate, endDate)

      for (let i = 0; i <= diffDays; i++) {
        if (weekdaysToMatch.includes(weekDays[currentDate.getDay()])) {
          const prettyCurrentDate = this.convertJavascriptDateToPrettyDate(currentDate)
          matchingDatesArray.push(prettyCurrentDate)
        }
        currentDate.setDate(currentDate.getDate() + 1)
      }
      return matchingDatesArray
    },
    convertJavascriptDateToPrettyDate (date) {
      date = new Date(date)
      let month = '' + (date.getMonth() + 1)
      let day = '' + date.getDate()
      const year = date.getFullYear()

      if (month.length < 2) {
        month = '0' + month
      }

      if (day.length < 2) {
        day = '0' + day
      }

      return [year, month, day].join('-')
    },
    daysBetweenDates (date1, date2) {
      const oneDay = 24 * 60 * 60 * 1000
      return Math.round(Math.abs((date2 - date1) / oneDay))
    },
    async saveTimeBlock () {
      this.validateForm()

      if (!this.formComplete) {
        return
      }

      this.validateDateTimeAvailability()

      if (this.dateTimeIsAvailable === false) {
        event.emit(Events.ERROR, 'This provider already has a prior scheduled appointment during this proposed time block.', 2000)
        return
      }

      this.formatDaysOfWeek()

      try {
        if (this.isCreateTimeBlock) {
          await this.createTimeBlock()
          return
        }
        await this.updateTimeBlock()
      } catch (error) {
        event.emit(Events.ERROR, error.response.data.payload.message, 2000)
      }
    },
    async createTimeBlock () {
      await window.axios.post(this.$store.getters['Organization/apiUrl'] + '/provider/schedule/time-blocks/', {
        time_block: this.time_block
      })
      event.emit(Events.SUCCESS, 'Time Block created successfully')
      this.cancel()
    },
    async updateTimeBlock () {
      await window.axios.put(this.$store.getters['Organization/apiUrl'] + '/provider/schedule/time-blocks/' + this.time_block.id, {
        time_block: this.time_block
      })
      event.emit(Events.SUCCESS, 'Time Block updated successfully')
      this.cancel()
    },
    checkForAllDays () {
      if (!this.time_block.days_of_week.includes('All')) {
        return
      }
      this.time_block.days_of_week = ['All']
    },
    disableDay (day) {
      if (this.time_block.days_of_week.length === 0) {
        return false
      }

      if (this.time_block.days_of_week.includes('All') && day.text !== 'All') {
        return true
      }

      return !this.time_block.days_of_week.includes('All') && day.text === 'All'
    },
    checkIsOngoing () {
      if (this.isOngoing) {
        this.time_block.days_of_week = ['All']
        return
      }

      this.time_block.end_date = null
      this.time_block.days_of_week = null
    },
    formatDaysOfWeek () {
      if (!this.isOngoing) {
        this.time_block.days_of_week = null
        return
      }
      if (this.time_block.days_of_week.includes('All')) {
        this.time_block.days_of_week = 'All'
        return
      }

      this.time_block.days_of_week = this.time_block.days_of_week.join(',')
    }
  }
}
</script>
