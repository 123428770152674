export const Roles = {
  ADMIN: 'admin',
  BASIC: 'basic',
  CLINIC_STAFF: 'clinic_staff',
  PROVIDER: 'provider',
  NO_ACCESS: 'no_access',
  INDIVIDUAL: 'individual'
}

export const RoleOptions = [
  { text: 'Admin', value: Roles.ADMIN },
  { text: 'Basic', value: Roles.BASIC },
  { text: 'Clinic Staff', value: Roles.CLINIC_STAFF },
  { text: 'Provider', value: Roles.PROVIDER },
  { text: 'No Access', value: Roles.NO_ACCESS },
  { text: 'Individual', value: Roles.INDIVIDUAL }
]

export const RoleInviteOptions = [
  { text: 'Basic - Standard access to manage patients and cases. Does not have access to site settings.', value: Roles.BASIC },
  { text: 'Clinic Staff - Clinic staff only has access to manage patient intake process.', value: Roles.CLINIC_STAFF },
  { text: 'Provider - NP, PT, PTA, MD, have access to manage patient evaluations and assessments.', value: Roles.PROVIDER },
  { text: 'Admin - Basic role plus administrative access.', value: Roles.ADMIN }
]
