import { _axios } from '../../plugins/axios'
import { clearInterval, setInterval } from 'worker-timers'

export default {
  namespaced: true,
  state: {
    id: null,
    name: null,
    email: null,
    token: {
      access_token: null,
      refresh_token: null,
      expires_at: null
    },
    invite_token: null,
    patient: {
      uuid: null,
      intake: null,
      intake_status: null
    },
    currentOrganization: {},
    organizations: [],
    activeLocationId: 'all',
    caseSearchParams: {},
    isProvider: false,
    providerId: '',
    providerType: null,
    providerLocations: [],
    userTasks: [],
    taskInterval: null
  },
  getters: {
    getId (state) {
      return state.id
    },
    name (state) {
      return state.name
    },
    getUserTasks (state) {
      return state.userTasks
    },
    activeLocationId (state) {
      return state.activeLocationId
    },
    hasAccess: (state) => (requiredRoles) => {
      if (Array.isArray(requiredRoles)) {
        return requiredRoles.findIndex(r => r === state.currentOrganization.role) !== -1
      }

      return false
    },
    initials (state) {
      return state.name.split(' ').map(s => s[0]).join('')
    },
    isLoggedIn (state) {
      const now = new Date()
      return !!state.token.access_token && new Date(state.token.expires_at) > now
    },
    belongsToMultipleOrganizations (state) {
      return state.organizations.length > 1
    },
    getInviteToken (state) {
      return state.invite_token
    },
    getCaseSearchParams (state) {
      return state.caseSearchParams
    },
    isProvider (state) {
      return state.isProvider
    },
    getProviderType (state) {
      return state.providerType
    },
    getProviderLocations (state) {
      return state.providerLocations
    },
    getProviderDefaultLocation (state) {
      return state.providerLocations[0]
    },
    getProviderId (state) {
      return state.providerId
    }
  },
  mutations: {
    updateUser (state, user) {
      state.id = user.id
      state.name = user.name
      state.email = user.email
      state.userTasks = user.user_tasks
    },
    // when patient is logged in
    updateUserPatient (state, patient) {
      state.patient.uuid = patient.uuid
      state.patient.intake = patient.intake
      state.patient.intake_status = patient.intake_status
    },
    updateToken (state, token) {
      state.token = token
    },
    updateInviteToken (state, token) {
      state.invite_token = token
    },
    setCurrentOrganization (state, currentOrganization) {
      state.currentOrganization = currentOrganization
    },
    updateOrganizations (state, organizations) {
      state.organizations = organizations
    },
    activeLocationId (state, locationId) {
      state.activeLocationId = locationId
    },
    caseSearchParams (state, payload) {
      state.caseSearchParams = payload
    },
    updateUserProvider (state, payload) {
      state.isProvider = true
      state.providerType = payload.type
      state.providerId = payload.id
      state.providerLocations = payload.locations.map(location => {
        return { id: location.id, name: location.name }
      })
    },
    updateUserTasks (state, tasks) {
      state.userTasks = tasks
    },
    setTaskInterval (state) {
      // patients do not have tasks
      if (state.patient.uuid) {
        return
      }

      state.taskInterval = setInterval(async () => {
        const response = await window.axios.get(`/v1/user/${state.id}/user-tasks`)
        state.userTasks = response.data.payload.user_tasks
      }, 300000)
      // interval set for 5 minutes (milliseconds)
    },
    clearTaskInterval (state) {
      if (state.patient.uuid) {
        return
      }

      state.taskInterval = null
      clearInterval(state.taskInterval)
    }
  },
  actions: {
    // payload should be caseSearchParams
    setActiveLocationId (ctx, payload) {
      ctx.commit('activeLocationId', payload.activeLocationId)
      ctx.dispatch('Case/getCases', payload, { root: true })
    },
    setCaseSearchParams (ctx, payload) {
      ctx.commit('caseSearchParams', payload)
    },
    updateTaskInterval (ctx) {
      ctx.commit('setTaskInterval')
    },
    async updateUserTasks (ctx) {
      const response = await window.axios.get('/v1/user')
      ctx.commit('updateUserTasks', response.data.payload.user_tasks)
    },
    async getSelf (ctx) {
      const response = await window.axios.get('/v1/user')

      if (response.data.payload.organizations.length === 0) {
        throw Error('Your account doesn\'t belong to any active organization.')
      }

      ctx.commit('updateUser', response.data.payload)
      ctx.commit('updateOrganizations', response.data.payload.organizations)
      if (response.data.payload.patient) {
        ctx.commit('updateUserPatient', response.data.payload.patient)
      }
      if (response.data.payload.invite) {
        ctx.commit('updateInviteToken', response.data.payload.invite.token)
      }
      if (response.data.payload.provider) {
        ctx.commit('updateUserProvider', response.data.payload.provider)
      }
      ctx.dispatch('Organization/updateOrganization', response.data.payload.organizations[0], { root: true })
    },
    async login (ctx, { email, password = '', token }) {
      const formData = new FormData()
      formData.append('grant_type', 'password')
      formData.append('client_id', process.env.VUE_APP_CLIENT_ID)
      formData.append('client_secret', process.env.VUE_APP_CLIENT_SECRET)
      formData.append('username', email)
      formData.append('password', password)
      if (token) {
        formData.append('token', token)
      }
      formData.append('scope', '*')
      try {
        const response = await _axios.post('/oauth/access-token', formData, {
          headers: {
            'Content-Type': 'x-www-form-urlencoded'
          }
        })

        const expiresAt = new Date()
        expiresAt.setSeconds(expiresAt.getSeconds() + response.data.expires_in)

        ctx.commit('updateToken', {
          access_token: response.data.access_token,
          refresh_token: response.data.refresh_token,
          expires_at: expiresAt
        })

        await ctx.dispatch('getSelf')
      } catch (e) {
        console.error(e)
        return Promise.reject(new Error('Login Failed'))
      }
    },
    async logout (ctx) {
      ctx.commit('updateToken', {
        access_token: null,
        refresh_token: null,
        expires_at: null
      })
      ctx.commit('clearTaskInterval')
    }
  }
}
