<template>
  <v-container v-if="encounter.id">
<!-- Dialogs -->
    <UpdatePatientHistoryDialog ref="updatePatientHistoryDialog" />
    <ConfirmSignDialog ref="signDialog" @sign="signEncounter"/>

<!-- Heading -->
    <v-row>
      <v-col>
        <h1>Provider Encounter</h1>
      </v-col>
      <v-col class="d-flex justify-end mt-3">
        <v-btn color="primary" @click="routeToPatientChart">Patient Chart</v-btn>
      </v-col>
    </v-row>
<!-- Patient Information -->
    <div class="grey lighten-3 text-black px-6 py-4 mt-6">
      <div class="d-flex justify-space-between align-center">
        <h3>Patient Information</h3>
      </div>
      <div class="mt-4">
        <v-row>
          <v-col sm="2" cols="12">
            <div class="demographic-header">Name:</div>
            <div>{{patient.full_name}}</div>
          </v-col>
          <v-col sm="2" cols="12">
            <div class="demographic-header">DOB:</div>
            <div>{{formatCarbonDateNoSetTime(patient.dob)}}</div>
          </v-col>
          <v-col sm="2" cols="12">
            <div class="demographic-header">Phone:</div>
            <div>{{formatPhone(patient.main_phone)}}</div>
          </v-col>
          <v-col sm="2" cols="12">
            <div class="demographic-header">Email:</div>
            <div>{{ patient.email }}</div>
          </v-col>
          <v-col sm="4" cols="12">
            <div class="demographic-header">Address:</div>
            <div>{{ patient.full_address }}</div>
          </v-col>
        </v-row>
      </div>
    </div>
<!-- Appointment Information -->
    <div class="grey lighten-3 text-black px-6 py-4">
      <div class="d-flex justify-space-between align-center">
        <h3>Appointment Information</h3>
      </div>
      <div class="mt-4">
        <v-row>
          <v-col sm="2" cols="12">
            <div class="demographic-header">Facility:</div>
            <div>{{encounter.appointment.location.name}}</div>
          </v-col>
          <v-col sm="2" cols="12">
            <div class="demographic-header">Visit Type:</div>
            <div>{{encounter.appointment.visit_type}}</div>
          </v-col>
          <v-col sm="2" cols="12">
            <div class="demographic-header">Status:</div>
            <div>{{encounter.appointment.status}}</div>
          </v-col>
          <v-col sm="2" cols="12">
            <div class="demographic-header">Provider:</div>
            <div>{{encounter.provider.provider_name }}</div>
          </v-col>
          <v-col sm="2" cols="12">
            <div class="demographic-header">Date:</div>
            <div>{{ formatCarbonDateNoSetTime(encounter.appointment.date) }}</div>
          </v-col>
          <v-col sm="2" cols="12">
            <div class="demographic-header">Time:</div>
            <div>{{ encounter.appointment.time }}</div>
          </v-col>
        </v-row>
      </div>
    </div>
<!--  Provider Signed    -->
      <div class="grey lighten-3 text-black px-6 py-4" v-if="encounter.provider_signed_at">
        <div class="d-flex justify-space-between align-center">
          <h3>Complete</h3>
        </div>
        <div class="mt-4">
          <v-row>
            <v-col sm="6" cols="12">
              <div class="demographic-header">Provider Signature:</div>
              <div>
                <span class="font-italic">{{encounter.provider.provider_name}} </span>
                <span>{{ formatCarbonDate(encounter.provider_signed_at) }}</span>
              </div>
            </v-col>
          </v-row>
        </div>
    </div>
    <div class="dialog-banner"></div>
    <v-row>
      <!--  Templates      -->
      <v-col v-if="showSave || showSign" :cols="currentSoapStep === 5 ? '2' : '5'">
        <div class="pa-1 pt-3">
          <h3>Edit any template, then add:</h3>
          <div class="templates-container">
            <EncounterTemplate
              v-for="template in filteredEncounterTemplates"
              :key="template.id"
              :template="template"
              :filtered-diagnoses="encounter.filtered_diagnoses"
              @add-prepared-template="addTemplateToSOAP($event)"
              @update-filter-diagnosis="updateFilteredDiagnoses($event)"
            />
          </div>
        </div>
      </v-col>
      <!--  Soap      -->
      <v-col :cols="soapColumns()">
        <v-stepper v-model="currentSoapStep" non-linear>
          <v-stepper-header>
            <v-stepper-step v-for="step in soapSteps" :editable="step.editable" :step="step.value" :key="step.value" @click="setSoapStep(step.value)">
              {{ step.text }}
            </v-stepper-step>
            <v-btn
              class="mt-5"
              color="primary"
              :disabled="!soapComplete"
              @click="$refs.signDialog.open(encounter.provider.provider_name)"
              v-show="showSign"
            >Sign</v-btn>
            <v-btn color="primary" class="mt-5" @click="saveEncounter" v-show="showSave">Save</v-btn>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content :step="1">
              <h3 class="mb-2">Subjective</h3>
              <v-textarea
                id="subjectiveTextarea"
                v-model="encounter.subjective"
                placeholder="Subjective..."
                filled
                dense
                rows="25"
              />
            </v-stepper-content>
            <v-stepper-content :step="2">
              <h3 class="mb-2">Objective</h3>
              <v-textarea
                id="objectiveTextarea"
                v-model="encounter.objective"
                placeholder="Subjective..."
                filled
                dense
                rows="25"
              />
            </v-stepper-content>
            <v-stepper-content :step="3">
              <h3 class="mb-2">Assessment</h3>
              <v-textarea
                id="assessmentTextarea"
                v-model="encounter.assessment"
                placeholder="Subjective..."
                dense
                filled
                rows="25"
              />
            </v-stepper-content>
            <v-stepper-content :step="4">
              <h3 class="mb-2">Plan</h3>
              <v-textarea
                id="planTextarea"
                v-model="encounter.plan"
                placeholder="Subjective..."
                filled
                dense
                rows="25"
              />
            </v-stepper-content>
            <v-stepper-content :step="5">
              <div>
                <!--  Encounters -->
                <div class="mt-2">
                  <Encounters ref="encounters" @encounterChange="getPatient" @routeEncounter="setEncounter"/>
                </div>

                <!--  Referrals  -->
                <div class="mt-14">
                  <PatientReferrals @referralChange="getPatient"/>
                </div>

                <!-- Patient History -->
                <div class="mt-14">
                  <h3>Patient History</h3>
                  <PatientLogs ref="patientLogs" />
                </div>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import { mapGetters } from 'vuex'
import PhoneMixin from '@/mixins/PhoneMixin'
import { Roles } from '@/js/Roles'
import PatientReferrals from '@/components/Provider/PatientReferrals.vue'
import Encounters from '@/components/Provider/Encounters.vue'
import UpdatePatientHistoryDialog from '@/components/Case/Dialogs/UpdatePatientHistoryDialog.vue'
import ConfirmSignDialog from '@/components/ProviderPatients/Dialogs/ConfirmSignDialog.vue'
import PatientLogs from '@/components/Provider/PatientLogs.vue'
import EncounterTemplate from '@/components/Provider/EncounterTemplate.vue'
import structuredClone from '@ungap/structured-clone'
import { formatCarbonDateNoSetTime, formatCarbonDate } from '@/js/PatientIntake/functions'
import event, { Events } from '@/event'

export default {
  name: 'ProviderEncounterView',
  components: { Encounters, PatientReferrals, UpdatePatientHistoryDialog, PatientLogs, ConfirmSignDialog, EncounterTemplate },
  mixins: [PhoneMixin],
  data: () => ({
    Roles,
    encounter: {},
    currentSoapStep: 1,
    soapSteps: [
      { text: 'S', editable: true, show: true, value: 1 },
      { text: 'O', editable: true, show: true, value: 2 },
      { text: 'A', editable: true, show: true, value: 3 },
      { text: 'P', editable: true, show: true, value: 4 },
      { text: 'History', editable: true, show: true, value: 5 }
    ],
    encounterTemplates: []
  }),
  computed: {
    ...mapGetters({
      patient: 'ProviderPatient/patientDemographics',
      url: 'Organization/apiUrl',
      currentEncounter: 'ProviderPatient/currentProviderEncounter',
      hasAccess: 'User/hasAccess',
      providerId: 'User/getProviderId'
    }),
    showSign () {
      if (this.encounter.provider_signed_at) {
        return false
      }

      if (this.hasAccess([Roles.ADMIN, Roles.BASIC, Roles.CLINIC_STAFF])) {
        return false
      }

      if (this.providerId !== this.encounter.provider_id) {
        return false
      }

      return true
    },
    showSave () {
      if (this.encounter.provider_signed_at) {
        return false
      }

      if (this.hasAccess([Roles.CLINIC_STAFF])) {
        return false
      }

      if (this.hasAccess([Roles.ADMIN, Roles.BASIC])) {
        return true
      }

      if (this.providerId !== this.encounter.provider_id) {
        return false
      }

      return true
    },
    soapComplete () {
      return !!this.encounter.subjective && !!this.encounter.objective && !!this.encounter.assessment && !!this.encounter.plan
    },
    filteredEncounterTemplates () {
      const soapSteps = ['S', 'O', 'A', 'P']
      return this.encounterTemplates.filter((template) => template.soap_category === soapSteps[this.currentSoapStep - 1])
    }
  },
  async mounted () {
    await this.getProviderEncounter()
    await this.getTemplates()
  },
  methods: {
    formatCarbonDateNoSetTime,
    formatCarbonDate,
    async getPatient () {
      await this.$store.dispatch('ProviderPatient/getCurrentPatient', this.$route.params.patientId)
    },
    async getProviderEncounter () {
      await this.$store.dispatch('ProviderPatient/getProviderEncounter', { patientId: this.$route.params.patientId, encounterId: this.$route.params.encounterId })
      this.setEncounter()
    },
    setEncounter () {
      this.encounter = structuredClone(this.currentEncounter)
    },
    async saveEncounter () {
      try {
        await window.axios.put(this.url + '/provider/patients/' + this.patient.id + '/provider-encounters/' + this.encounter.id, {
          subjective: this.encounter.subjective,
          objective: this.encounter.objective,
          assessment: this.encounter.assessment,
          plan: this.encounter.plan,
          filtered_diagnoses: this.encounter.filtered_diagnoses
        })
        event.emit(Events.SUCCESS, 'Provider Encounter updated successfully')
      } catch (error) {
        event.emit(Events.ERROR, error.response.data.payload.message, 2000)
      }
    },
    async signEncounter () {
      if (!this.soapComplete) {
        event.emit(Events.ERROR, 'Error: SOAP is not complete', 2000)
        return
      }

      try {
        const response = await window.axios.put(this.url + '/provider/patients/' + this.patient.id + '/provider-encounters/' + this.encounter.id + '/sign', {
          subjective: this.encounter.subjective,
          objective: this.encounter.objective,
          assessment: this.encounter.assessment,
          plan: this.encounter.plan
        })
        event.emit(Events.SUCCESS, 'Provider Encounter signed successfully')
        this.encounter = structuredClone(response.data.payload[0])
      } catch (error) {
        event.emit(Events.ERROR, error.response.data.payload.message, 2000)
      }
    },
    setSoapStep (stepValue) {
      this.currentSoapStep = stepValue
    },
    soapColumns () {
      if (!this.showSign && !this.showSave) {
        return '12'
      }

      if (this.currentSoapStep === 5) {
        return '10'
      }

      return '7'
    },
    routeToPatientChart () {
      this.$router.push({ name: 'PatientChartEditView', params: { patientId: this.$route.params.patientId.toString() } })
    },
    async getTemplates () {
      const rawData = await window.axios.get(this.url +
        '/provider/encounter-templates' +
        '?visit_type=' + this.encounter.appointment.visit_type)
      this.encounterTemplates = rawData.data.payload.provider_encounter_templates
    },
    addTemplateToSOAP (text) {
      const soapSteps = ['subjective', 'objective', 'assessment', 'plan']
      if (!this.encounter[soapSteps[this.currentSoapStep - 1]]) {
        this.encounter[soapSteps[this.currentSoapStep - 1]] = text
      } else {
        this.encounter[soapSteps[this.currentSoapStep - 1]] = this.encounter[soapSteps[this.currentSoapStep - 1]] + '\n\n' + text
      }
      this.$nextTick(() => {
        const textareaElement = document.getElementById(`${soapSteps[this.currentSoapStep - 1]}Textarea`)
        textareaElement.scrollTop = textareaElement.scrollHeight
      })
    },
    updateFilteredDiagnoses (diagnoses) {
      this.encounter.filtered_diagnoses = { diagnoses: diagnoses }
    }
  }
}
</script>
<style scoped>
.demographic-header {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 3px;
}

.dialog-banner {
  background-color: #043561;
  color: #ffffff;
  height: 30px;
}

.templates-container {
  max-height: 827px;
  overflow-y: auto
}
</style>
