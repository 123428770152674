<template>
    <v-container>
      <h1>Provider Schedules</h1>
<!--  Dialogs -->
      <SearchPatientDialog
        ref="searchPatientDialog"
        :calendar-events="events"
        @search-patient-dialog-closing="fetchAppointments"
      />
      <ManageProviderTimeBlocksDialog
        ref="manageProviderTimeBlocksDialog"
        :location-providers="locationProviders"
        :location-id="selectedLocation"
        :calendar-events="events"
        @manage-provider-time-blocks-dialog-closing="fetchAppointments"
      />
      <UpdateProviderPatientAppointmentDialog
        ref="updateProviderPatientAppointmentDialog"
        :calendar-events="events"
        @update-appointment-dialog-closing="fetchAppointments"
      />
      <CreateAndUpdateTimeBlocksDialog
        ref="createAndUpdateTimeBlocksDialog"
        @fetch-time-blocks="fetchAppointments"
        :calendar-events="events"
      />
<!--  Patient Search -->
        <v-btn small class="primary pa-5 mt-5"
               @click="$refs.searchPatientDialog.open()">
          <v-icon class="mr-4">fas fa-user</v-icon>Search Patient
        </v-btn>
<!--  ProviderSchedule  -->
        <v-row class="fill-height mt-8 mb-8">
          <v-col>
            <v-sheet height="64">
              <v-toolbar
                flat
              >
                <v-btn
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="setToday"
                >
                  Today
                </v-btn>
                <v-btn
                  fab
                  text
                  small
                  color="grey darken-2"
                  @click="prev"
                >
                  <v-icon small>
                    mdi-chevron-left
                  </v-icon>
                </v-btn>
                <v-menu
                  ref="datePickerMenu"
                  v-model="showDatePicker"
                  bottom
                  offset-y
                  >
                  <template v-slot:activator="{ on }">
                      <v-btn
                        small fab
                        elevation="0"
                        color="primary"
                        v-on="on"
                        class="mx-2"
                      >
                        <v-icon small>fa-calendar-plus</v-icon>
                      </v-btn>
                  </template>
                  <v-date-picker
                    v-model="datePicker"
                    elevation="2"
                    @change="updateCalendarDate"
                  ></v-date-picker>
                </v-menu>
                <v-btn
                  fab
                  text
                  small
                  color="grey darken-2"
                  @click="next"
                >
                  <v-icon small>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
                <v-toolbar-title class="ml-4" v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-select
                  ref="locationSelect"
                  :items="locations"
                  item-text="name"
                  item-value="id"
                  v-model="selectedLocation"
                  label="Location"
                  dense
                  filled
                  class="ml-3 CaseStatus"
                  hide-details
                  @input="fetchAppointments"
                />
              </v-toolbar>
            </v-sheet>
            <v-sheet
              height="600"
              class="mt-4"
            >
              <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
                type="category"
                category-show-all
                :categories="categories"
                :events="events"
                :event-color="getEventColor"
                @change="addEventsToCalendar"
                interval-minutes="15"
                first-interval="31"
                interval-count="38"
                @click:event="$event.event.isTimeBlock ? $refs.createAndUpdateTimeBlocksDialog.open($event.event.fullTimeBlockData) : $refs.updateProviderPatientAppointmentDialog.open($event)"
              >
                <template v-slot:event="{ event }">
                  <div v-if="event.isTimeBlock" id="event-container" class="d-flex align-center">
                    <div id="visit-type-container">
                      <div id="visit-type-circle" class="d-flex grey justify-center align-center">
                      </div>
                    </div>
                    <div id="timeblock-content-container" class="d-flex align-start justify-start pa-4 black--text w-100">
                      <div class="d-flex flex-column">
                        <span>{{ event.isFridayAfternoon ? 'CLOSED on Friday Afternoons' : event.name }}</span>
                        <span>{{ `${event.prettyStartTime} - ${event.prettyEndTime}` }}</span>
                      </div>
                    </div>
                    </div>
                  <div v-else id="event-container" class="d-flex align-center">
                    <div id="visit-type-container">
                      <div id="visit-type-circle" class="d-flex justify-center align-center" :class="event.visitTypeColor">
                        <v-icon class="white--text" v-if="event.visitType.startsWith('Tele')">fa-phone-volume</v-icon>
                      </div>
                    </div>
                    <div id="appointment-content-container" class="d-flex align-center justify-start pa-2 black--text w-100">
                      <div class="d-flex flex-column">
                        <span>{{ event.name }}</span>
                        <span>{{ `${event.prettyStartTime} - ${event.prettyEndTime}` }}</span>
                      </div>
                      <div id="appointment-content-status-container">
                        <span id="appointment-content-status" class="rounded-pill d-flex align-center justify-center pa-2">{{ event.status }}</span>
                      </div>
                    </div>
                  </div>
                </template>
              </v-calendar>
            </v-sheet>
            <div class="d-flex justify-space-between mt-4 w-100">
              <v-btn
                v-show="hasAccess([Roles.ADMIN, Roles.BASIC])"
                color="primary"
                @click="$refs.manageProviderTimeBlocksDialog.open()"
              >
                <v-icon small class="mr-4">fa-calendar-times</v-icon>Manage Provider Time Blocks
              </v-btn>
            <v-menu
              v-model="visitTypeMenu"
              :close-on-content-click="false"
              :nudge-width="200"
              top
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <div class="d-flex align-start">
                    <div class="color-legend-title blue darken-1"></div>
                    <div class="color-legend-title orange darken-1"></div>
                    <div class="color-legend-title green darken-1"></div>
                    <div class="color-legend-title pink darken-1"></div>
                  </div>
                  <span class="ml-3">Visit Type Color Legend</span>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="primary white--text">Visit Type Color Legend</v-card-title>
                <div class="pa-4 d-flex flex-column">
                  <div class="d-flex" v-for="type in VisitTypeOptions" :key="type.value">
                    <div class="d-flex ma-1">
                      <div id="legend-box" :class="type.color">
                        <v-icon class="white--text" v-if="type.value.startsWith('Tele')">fa-phone-volume</v-icon>
                      </div>
                      <span class="ml-3 d-flex align-center" >{{ type.value }}</span>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-menu>
            </div>
          </v-col>
        </v-row>
<!--  User Tasks -->
      <div class="mt-8">
        <h3 class="mb-4">Tasks</h3>
        <v-alert v-if="!userTasks || userTasks.length === 0" dense text type="success">No Pending Tasks</v-alert>
        <div>
          <v-alert
            dense
            text
            type="info"
            v-for="(userTask, index) in userTasks" :key="index"
            class="mb-6"
          >
              <div class="d-flex justify-space-between align-center">
                <div>
                  <strong style="">{{userTask.task.title}}</strong>
                  <div v-if="userTask.task.description">{{userTask.task.description}}</div>
                </div>
                <v-btn
                  v-if="userTask.task.required_action === TaskRequiredActions.ACKNOWLEDGE"
                  small
                  color="primary"
                  dark
                  class="ml-3"
                  @click="acknowledgeTask(userTask.id)"
                >Acknowledge</v-btn>
              </div>
          </v-alert>
        </div>
      </div>
    </v-container>
</template>
<script>
import SearchPatientDialog from '@/components/ProviderPatients/Dialogs/SearchPatientDialog.vue'
import ManageProviderTimeBlocksDialog from '@/components/ProviderPatients/Dialogs/ManageProviderTimeBlocksDialog.vue'
import { TaskRequiredActions } from '@/js/Provider/Task'
import event, { Events } from '@/event'
import { mapGetters, mapState } from 'vuex'
import { VisitTypeOptions, VisitStatusOptions } from '@/js/Provider/AppointmentEnums'
import structuredClone from '@ungap/structured-clone'
import { ProviderTypes } from '@/js/ProviderTypes'
import UpdateProviderPatientAppointmentDialog
  from '@/components/ProviderPatients/Dialogs/UpdateProviderPatientAppointmentDialog.vue'
import { Roles } from '@/js/Roles'
import { formatCarbonDateNoSetTime } from '@/js/PatientIntake/functions'
import CreateAndUpdateTimeBlocksDialog from '@/components/ProviderPatients/Dialogs/CreateAndUpdateTimeBlocksDialog.vue'

export default {
  name: 'ProviderSchedules',
  components: { CreateAndUpdateTimeBlocksDialog, SearchPatientDialog, ManageProviderTimeBlocksDialog, UpdateProviderPatientAppointmentDialog },
  mixins: null,
  data: () => ({
    formatCarbonDateNoSetTime,
    Roles,
    ProviderTypes,
    selectedLocation: null,
    locationProviders: null,
    focus: new Date(),
    events: [],
    categories: [],
    TaskRequiredActions,
    VisitTypeOptions,
    VisitStatusOptions,
    appointments: [],
    providerTimeBlocks: [],
    visitTypeMenu: false,
    showDatePicker: false,
    datePicker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
  }),
  computed: {
    ...mapGetters({
      userTasks: 'User/getUserTasks',
      userId: 'User/getId',
      url: 'Organization/apiUrl',
      hasAccess: 'User/hasAccess'
    }),
    ...mapState({
      isProvider: state => state.User.isProvider,
      providerLocations: state => state.User.providerLocations,
      providers: state => state.Organization.providers,
      locations: state => state.Organization.locations
    })
  },
  mounted () {
    if (this.isProvider && this.providerLocations.length > 0) {
      this.selectedLocation = this.providerLocations[0].id
    } else {
      this.selectedLocation = this.locations[0].id
    }
    this.fetchAppointments()
  },
  methods: {
    async acknowledgeTask (userTaskId) {
      this.$store.commit('loading', true)
      event.emit(Events.LOADING, true)
      try {
        await window.axios.delete(`v1/user/${this.userId}/user-tasks/${userTaskId}`)
        await this.$store.dispatch('User/updateUserTasks')
      } catch (error) {
        event.emit(Events.ERROR, error.response.data.payload.message, 400)
      }

      this.$store.commit('loading', false)
      event.emit(Events.LOADING, false)
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = new Date()
    },
    prev () {
      this.focus = new Date(this.focus.getTime() - 60 * 60 * 24 * 1000)
    },
    next () {
      this.focus = new Date(this.focus.getTime() + 60 * 60 * 24 * 1000)
    },
    mapVisitTypeToColor (visitType) {
      const visitTypeOption = VisitTypeOptions.filter((type) => type.value === visitType)
      return visitTypeOption[0].color
    },
    async fetchAppointments () {
      try {
        await this.updateProviderNames()
        const appointments = await this.$axios.get(`${this.url}/provider/schedule/appointments/${this.selectedLocation}`)
        this.appointments = appointments.data.payload.appointments
        await this.fetchTimeBlocks()
        this.addEventsToCalendar({})
      } catch (error) {
        event.emit(Events.ERROR, error.response.data.payload.message, 2000)
      }
    },
    async fetchTimeBlocks () {
      try {
        const rawData = await window.axios.get(this.$store.getters['Organization/apiUrl'] + '/provider/schedule/time-blocks/' + this.selectedLocation)
        this.providerTimeBlocks = rawData.data.payload['time-blocks']
      } catch (error) {
        event.emit(Events.ERROR, error.response.data.payload.message, 2000)
      }
    },
    async updateProviderNames () {
      await this.$store.dispatch('Organization/getProviders')
      const providers = structuredClone(this.providers)

      const filteredProviders = providers.filter((provider) => provider.locations
        .some(location => location.id === this.selectedLocation))

      this.locationProviders = filteredProviders

      this.categories = Object.values(filteredProviders.map((provider) => provider.pretty_provider))
    },
    addEventsToCalendar ({ start, end }) {
      this.events = this.addAppointmentEvents().concat(this.addTimeBlockEvents()).concat(this.addFridayAfternoonTimeBlocks())
    },
    updateCalendarDate (date) {
      this.focus = new Date(date + 'T12:00:00')
    },
    getStatusTextFromValue (value) {
      const status = VisitStatusOptions.find((status) => status.value === value)
      return status.text
    },
    addAppointmentEvents () {
      const events = []
      for (const appointment of this.appointments) {
        const startDateTime = new Date(`${appointment.date} ${appointment.time}`)
        const endDateTime = new Date(`${appointment.date} ${appointment.time}`)
        endDateTime.setMinutes(endDateTime.getMinutes() + +appointment.duration)

        const prettyStartTime = startDateTime.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        })
        const prettyEndTime = endDateTime.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        })

        events.push({
          name: appointment.patient.full_name,
          visitType: appointment.visit_type,
          visitTypeColor: this.mapVisitTypeToColor(appointment.visit_type),
          start: startDateTime,
          end: endDateTime,
          prettyStartTime: prettyStartTime,
          prettyEndTime: prettyEndTime,
          status: this.getStatusTextFromValue(appointment.status),
          color: 'grey lighten-3',
          timed: true,
          category: appointment.provider.pretty_provider,
          fullAppointmentData: appointment,
          isTimeBlock: false
        })
      }
      return events
    },
    addTimeBlockEvents () {
      const events = []

      for (const timeBlock of this.providerTimeBlocks) {
        if (timeBlock.end_date) {
          let daysOfWeekArray = []
          if (timeBlock.days_of_week === 'All') {
            daysOfWeekArray = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
          } else {
            daysOfWeekArray = timeBlock.days_of_week.split(',')
          }

          const daysToAdd = this.getDatesByRangeWithMatchingWeekdays(timeBlock.start_date, timeBlock.end_date, daysOfWeekArray)
          for (const day of daysToAdd) {
            const startDateTime = new Date(`${day} ${timeBlock.start_time}`)
            const endDateTime = new Date(`${day} ${timeBlock.end_time}`)

            const prettyStartTime = startDateTime.toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true
            })
            const prettyEndTime = endDateTime.toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true
            })

            events.push({
              name: 'Provider Time Block',
              start: startDateTime,
              end: endDateTime,
              prettyStartTime: prettyStartTime,
              prettyEndTime: prettyEndTime,
              color: 'grey lighten-3',
              timed: true,
              category: timeBlock.provider.pretty_provider,
              fullTimeBlockData: timeBlock,
              isTimeBlock: true
            })
          }
        } else {
          const startDateTime = new Date(`${timeBlock.start_date} ${timeBlock.start_time}`)
          const endDateTime = new Date(`${timeBlock.end_date ? timeBlock.end_date : timeBlock.start_date} ${timeBlock.end_time}`)

          const prettyStartTime = startDateTime.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
          })
          const prettyEndTime = endDateTime.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
          })

          events.push({
            name: 'Provider Time Block',
            start: startDateTime,
            end: endDateTime,
            prettyStartTime: prettyStartTime,
            prettyEndTime: prettyEndTime,
            color: 'grey lighten-3',
            timed: true,
            category: timeBlock.provider.pretty_provider,
            fullTimeBlockData: timeBlock,
            isTimeBlock: true
          })
        }
      }
      return events
    },
    getDatesByRangeWithMatchingWeekdays (prettyStartDate, prettyEndDate, weekdaysToMatch) {
      const matchingDatesArray = []
      const currentDate = new Date(prettyStartDate + 'T12:00:00')
      const endDate = new Date(prettyEndDate + 'T12:00:00')

      const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

      const diffDays = this.daysBetweenDates(currentDate, endDate)

      for (let i = 0; i <= diffDays; i++) {
        if (weekdaysToMatch.includes(weekDays[currentDate.getDay()])) {
          const prettyCurrentDate = this.convertJavascriptDateToPrettyDate(currentDate)
          matchingDatesArray.push(prettyCurrentDate)
        }
        currentDate.setDate(currentDate.getDate() + 1)
      }
      return matchingDatesArray
    },
    convertJavascriptDateToPrettyDate (date) {
      date = new Date(date)
      let month = '' + (date.getMonth() + 1)
      let day = '' + date.getDate()
      const year = date.getFullYear()

      if (month.length < 2) {
        month = '0' + month
      }

      if (day.length < 2) {
        day = '0' + day
      }

      return [year, month, day].join('-')
    },
    daysBetweenDates (date1, date2) {
      const oneDay = 24 * 60 * 60 * 1000
      return Math.round(Math.abs((date2 - date1) / oneDay))
    },
    addFridayAfternoonTimeBlocks () {
      const events = []
      const oneYearBehindDate = new Date()
      oneYearBehindDate.setFullYear(oneYearBehindDate.getFullYear() - 1)
      const oneYearAheadDate = new Date()
      oneYearAheadDate.setFullYear(oneYearAheadDate.getFullYear() + 1)

      this.providers.forEach((provider) => {
        const daysToAdd = this.getDatesByRangeWithMatchingWeekdays(
          this.convertJavascriptDateToPrettyDate(oneYearBehindDate),
          this.convertJavascriptDateToPrettyDate(oneYearAheadDate),
          'Friday')

        daysToAdd.forEach((day) => {
          const startDateTime = new Date(`${day} 12:00 PM`)
          const endDateTime = new Date(`${day} 5:00 PM`)

          const prettyStartTime = startDateTime.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
          })
          const prettyEndTime = endDateTime.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
          })

          events.push({
            name: 'Provider Time Block',
            start: startDateTime,
            end: endDateTime,
            prettyStartTime: prettyStartTime,
            prettyEndTime: prettyEndTime,
            color: 'grey lighten-3',
            timed: true,
            category: provider.pretty_provider,
            fullTimeBlockData: {
              provider_id: provider.id
            },
            isTimeBlock: true,
            isFridayAfternoon: true
          })
        })
      })
      return events
    }
  }
}
</script>
<style scoped>
#event-container {
  height: 100%
}
#visit-type-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
#visit-type-circle {
  padding: 5px;
  min-height: 48px;
  height: 100%;
  width: 30px;
}
#legend-box {
  height: 30px;
  width: 30px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-content: center;
}
.color-legend-title {
  height: 10px;
  width: 10px;
  margin-right: 2px;
  border-radius: 1px;
}
#appointment-content-container {
  width: 100%;
  height: 100%
}
#appointment-content-status {
  background: #043561;
  height: 12px;
  color: white;
  font-size: 10px;
  position: absolute;
  right: 8px;
}
#appointment-content-status-container {
  height: 100%
}
#timeblock-content-container {
  width: 100%;
  height: 100%;
  font-weight: bold;
  background: repeating-linear-gradient(
    45deg,
    #dcddea,
    #dcddea 10px,
    #cbcbd7 10px,
    #cbcbd7 20px
  );
}
</style>
