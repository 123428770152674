<template>
  <v-dialog v-model="dialog" max-width="600" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title class="dialog-banner">Remove Alert</v-card-title>
      <v-card-text class="pb-0">
        <div class="text-center font-weight-bold mt-4">Are you sure you want to remove this alert?</div>
        <v-form class="mt-4" ref="form" v-model="formValid">
          <v-textarea
            v-model="note"
            filled
            label="Reason for removing alert..."
            :rules="[rules.required]"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn text @click="cancel">Cancel</v-btn>
        <v-btn depressed color="error" @click="removeAlert" :loading="loading">Remove Alert</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import RulesMixin from '@/mixins/RulesMixin'
import event, { Events } from '@/event'
import { PatientAlerts } from '@/js/Provider/Alert'
import { mapGetters } from 'vuex'

export default {
  name: 'RemoveAlertDialog',
  mixins: [RulesMixin],
  components: {},
  data: () => ({
    PatientAlerts,
    dialog: false,
    loading: false,
    alertId: null,
    formValid: true,
    note: ''
  }),
  computed: {
    ...mapGetters({
      url: 'Organization/apiUrl',
      patient: 'ProviderPatient/patientDemographics',
      alerts: 'ProviderPatient/patientAlerts'
    })
  },
  methods: {
    async open (alertId) {
      this.alertId = alertId
      this.dialog = true
    },
    cancel () {
      this.reset()
    },
    reset () {
      this.$refs.form.reset()
      this.alertId = null
      this.dialog = false
    },
    async removeAlert () {
      this.loading = true
      this.$refs.form.validate()

      if (!this.formValid) {
        this.loading = false
        return
      }

      try {
        await window.axios.delete(this.$store.getters['Organization/apiUrl'] + '/provider/patients/' + this.patient.id + '/alerts/' + this.alertId, {
          params: {
            note: this.note
          }
        })
        await this.$store.dispatch('User/updateUserTasks')
      } catch (error) {
        event.emit(Events.ERROR, error.response.data.payload.message, 400)
      }

      this.$emit('removedAlert')
      this.loading = false
      this.reset()
    }
  }
}
</script>
<style scoped>
.dialog-banner {
  background-color: #ff5252;
  color: #ffffff;
}
</style>
