<template>
  <div>
<!-- Dialogs   -->
    <UpdatePatientHistoryDialog ref="updatePatientHistoryDialog" />
<!-- Logs  -->
    <v-data-table
      :items="logs"
      :headers="logHeaders"
      :disable-sort="true"
      @click:row="$refs.updatePatientHistoryDialog.open($event)"
    >
      <template #item.createdAt="{ item }">
        {{ formatCarbonDate(item.created_at) }}
      </template>
      <template #item.userName="{ item }">
        {{ item.user.name }}
      </template>
      <template #item.action="{ item }">
        {{ item.action }}
      </template>
      <template #item.appointmentId="{ item }">
        {{ item.appointment_id ? item.appointment_id : '---'}}
      </template>
      <template #item.patientNote="{ item }">
        <span>{{ formatPatientNote(item.note) }}</span>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { formatCarbonDate } from '@/js/PatientIntake/functions'
import UpdatePatientHistoryDialog from '@/components/Case/Dialogs/UpdatePatientHistoryDialog.vue'
export default {
  components: { UpdatePatientHistoryDialog },
  data: () => ({
    logHeaders: [
      { text: 'Created At', value: 'createdAt' },
      { text: 'Author', value: 'userName' },
      { text: 'Action', value: 'action' },
      { text: 'Appointment Id', value: 'appointmentId' },
      { text: 'Note', value: 'patientNote' }
    ]
  }),
  computed: {
    ...mapGetters({
      logs: 'ProviderPatient/patientLogs'
    })
  },
  methods: {
    formatCarbonDate,
    formatPatientNote (note) {
      if (!note) {
        return '---'
      }
      if (note.length > 25) {
        return note.substring(0, 25) + '...'
      } else {
        return note
      }
    }
  }
}
</script>
