<template>
  <v-card flat class="mt-4">
    <v-row>
      <v-col>
        <h3>Documents</h3>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn small bottom color="primary" @click="fileUpload = true" v-if="hasAccess([Roles.ADMIN, Roles.BASIC, Roles.PROVIDER])">
          <v-icon small left>
            fa-upload
          </v-icon>
          Upload
        </v-btn>
      </v-col>
    </v-row>
    <v-card-text class="pa-0">
      <v-data-table :headers="headers" class="mt-3" :items="files || []">
        <template #no-data>
          This case has no files.  Use upload button to add one.
        </template>
        <template #item.size="{ item }">
          {{ humanFileSize(item.size) }}
        </template>
        <template #item.manage="{ item }">
          <v-btn :loading="fetching === item.id" x-small color="primary" class="mr-1" @click="download(item.id,  item.name, item.mime)">
            <v-icon x-small>
              fa-download
            </v-icon>
          </v-btn>
          <v-btn
            v-if="$store.getters['Organization/isHealthClinic'] && hasAccess([Roles.ADMIN])"
            :loading="deleting === item.id" x-small color="error" @click="deleteFile(item.id)">
            <v-icon x-small>
              fa-trash
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <FilesUpload @complete="$emit('filesChange')" :case-id="caseId" :open="fileUpload" @close="fileUpload = false" />
  </v-card>
</template>
<script>
import FilesUpload from './FilesUpload.vue'
import { mapGetters } from 'vuex'
import event, { Events } from '../../event'
import { Roles } from '@/js/Roles'

export default {
  components: { FilesUpload },
  props: {
    caseId: {
      type: Number,
      required: true
    },
    files: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    Roles,
    deleting: 0,
    fetching: 0,
    fileUpload: false,
    headers: [
      {
        text: 'File Name',
        value: 'name'
      },
      {
        text: 'File Size',
        value: 'size'
      },
      {
        text: 'Manage',
        value: 'manage',
        sortable: false
      }
    ]
  }),
  computed: {
    ...mapGetters({
      url: 'Organization/apiUrl',
      hasAccess: 'User/hasAccess'
    })
  },
  methods: {
    download (fileId, filename, mime) {
      this.fetching = fileId
      this.$axios.get(`${this.url}/case/${this.caseId}/files/${fileId}`, {
        responseType: 'blob'
      })
        .then((response) => {
          const blob = new Blob([response.data], { type: mime || 'application/octet-stream' })
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.style.display = 'none'
          a.href = url
          a.download = filename
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .finally(() => {
          this.fetching = 0
        })
    },
    deleteFile (fileId) {
      event.emit(Events.CONFIRM, 'Remove File?', () => {
        this.deleting = fileId
        this.$axios.delete(`${this.url}/case/${this.caseId}/files/${fileId}`)
          .then(() => {
            this.$emit('filesChange')
          })
          .finally(() => {
            this.deleting = 0
          })
      })
    },
    humanFileSize (bytes, si = false, dp = 1) {
      const thresh = si ? 1000 : 1024

      if (Math.abs(bytes) < thresh) {
        return bytes + ' B'
      }

      const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
      let u = -1
      const r = 10 ** dp

      do {
        bytes /= thresh
        ++u
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

      return bytes.toFixed(dp) + ' ' + units[u]
    }
  }
}
</script>
