<template>
  <v-container>
    <h1>Invite Users</h1>
    <v-combobox
      v-model='emails'
      :disabled="sending"
      label='Email Address'
      :error-messages="!emailsValid && emails.length > 0 ? 'One or more emails are invalid.' : ''"
      multiple filled chips
      hint='Enter up to 10 email addresses. Use the "Enter" key to add an email address to the list.'
      persistent-hint
      :rules="[rules.requiredMultiSelect]"
    >
      <template v-slot:selection="data">
        <v-chip
          :key="JSON.stringify(data.item)"
          v-bind="data.attrs"
          :input-value="data.selected"
          :disabled="data.disabled"
          @click:close="data.parent.selectItem(data.item)"
        >
          <v-avatar
            class="accent white--text"
            left
            :color="!validateEmail(data.item) ? 'error' : 'success'"
          >
            <v-icon color="white" v-if="validateEmail(data.item)">fas fa-check</v-icon>
            <v-icon color="white" v-else>fas fa-times</v-icon>
          </v-avatar>
          {{ data.item }}
          <v-icon @click="removeEmail(data.index)" small right>
            fas fa-times
          </v-icon>
        </v-chip>
      </template>
    </v-combobox>

    <v-select
      label="Role"
      v-model="role"
      :disabled="sending"
      @input="roleChanged"
      :items="roles"
      class="my-6"
      filled
      hint="Assign a permissions role for the user. You can change this role later in the user settings."
      persistent-hint
      :rules="[rules.required]"
    />

    <v-row v-if="showProviderDetails">
      <v-col cols="6">
        <v-select
          label="Location"
          v-model="selectedProviderLocations"
          :disabled="sending"
          :items="updatedProviderLocations"
          item-text="name"
          item-value="id"
          class="my-6"
          hint="Assign any location(s) for the provider."
          multiple
          small-chips
          filled
          dense
          clearable
          :rules="[rules.requiredMultiSelect]"
        />
      </v-col>
      <v-col cols="6">
        <v-select
          label="Provider Type"
          v-model="selectedProviderType"
          :disabled="sending"
          :items="ProviderOptions"
          class="my-6"
          filled
          hint="Assign a type for the provider."
          persistent-hint
          :rules="[rules.required]"
        />
      </v-col>
    </v-row>

    <div class="mb-6">
      <a class="d-flex align-content-center" @click="showCustomEmail = !showCustomEmail">
        <v-icon v-if="!showCustomEmail" small>fa-chevron-right</v-icon>
        <v-icon v-else small>fa-chevron-down</v-icon>
        <span class="ml-2">Personalize email invite</span>
      </a>
      <div v-if="showCustomEmail">
        <v-textarea :disabled="sending" v-model="customEmailMessage" filled :placeholder="'Hi, \n\nWelcome to...'"/>
      </div>
    </div>

    <v-btn depressed :loading="sending" color="primary" @click="inviteUsers" :disabled="!enableInvite">Invite User</v-btn>
    <v-btn :to="{ name: 'OrganizationUsers' }" text class="ml-2">Cancel</v-btn>
  </v-container>
</template>
<script>
import { email } from '../../../library/Validators'
import { mapGetters, mapState } from 'vuex'
import event, { Events } from '../../../event'
import { Roles, RoleInviteOptions } from '@/js/Roles'
import { ProviderOptions } from '@/js/ProviderTypes'
import RulesMixin from '@/mixins/RulesMixin'
import structuredClone from '@ungap/structured-clone'

export default {
  name: 'InviteUsers',
  mixins: [RulesMixin],
  data: () => ({
    Roles,
    showCustomEmail: false,
    sending: false,
    emails: [],
    role: Roles.BASIC,
    customEmailMessage: '',
    roles: [...RoleInviteOptions],
    ProviderOptions,
    updatedProviderLocations: [],
    selectedProviderType: 'NP',
    selectedProviderLocations: []
  }),
  computed: {
    ...mapState({
      providerLocations: state => state.Organization.locations
    }),
    ...mapGetters({
      url: 'Organization/apiUrl'
    }),
    enableInvite () {
      if (!this.emailsValid) {
        return false
      }
      if (this.role === Roles.PROVIDER && this.selectedProviderLocations.length === 0) {
        return false
      }
      return true
    },
    emailsValid () {
      if (this.emails.length === 0) {
        return false
      }

      let valid = true

      this.emails.forEach(e => {
        if (!this.validateEmail(e)) {
          valid = false
        }
      })

      return valid
    },
    showProviderDetails () {
      this.updateProviderLocations()
      return this.role === Roles.PROVIDER
    }
  },
  methods: {
    roleChanged () {
      this.selectedProviderType = 'NP'
      this.selectedProviderLocations = []
    },
    removeEmail (index) {
      this.emails.splice(index, 1)
    },
    validateEmail (value) {
      return email(value)
    },
    inviteUsers () {
      if (!this.emailsValid) {
        return
      }

      this.sending = true

      const promises = []

      this.emails.forEach((e) => {
        const invitePayload = {
          email: e,
          role: this.role,
          metadata: {}
        }

        if (this.role === Roles.PROVIDER) {
          if (this.selectedProviderLocations.length === 0) {
            return
          }

          invitePayload.metadata = {
            type: this.selectedProviderType,
            location_ids: this.selectedProviderLocations
          }
        }

        promises.push(this.$axios.post(`${this.url}/user/invite`, invitePayload))
      })

      Promise.all(promises)
        .then(() => {
          event.emit(Events.SUCCESS, 'Invites sent!')
          this.$router.push('/organization/settings/users')
        })
    },
    updateProviderLocations () {
      this.updatedProviderLocations = structuredClone(this.providerLocations)
    }
  }
}
</script>
