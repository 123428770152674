<template>
  <v-dialog v-model="dialog" max-width="400" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title class="primary white--text">Note Details</v-card-title>
      <v-card-text class="pt-3">
        {{ appointmentNote }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancel">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import RulesMixin from '@/mixins/RulesMixin'
export default {
  name: 'AppointmentNoteDialog',
  mixins: [RulesMixin],
  data: () => ({
    dialog: false,
    appointmentNote: ''
  }),
  methods: {
    open (note) {
      this.appointmentNote = note
      this.dialog = true
    },
    cancel () {
      this.dialog = false
    }
  }
}
</script>
