<template>
  <v-menu
    v-model="showPicker"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{on}">
      <v-text-field
        v-model="inputDate"
        :label="label"
        :placeholder="placeholder"
        v-mask="['##/##/####']"
        filled
        :class="classItems"
        :style="styleItems"
        @change="inputChanged"
        @blur="lostFocus"
        :rules="[
          !!inputDate || dateValid
        ]"
        :disabled="disabled"
      >
        <template v-slot:append>
          <v-icon v-on="on" @click="toggleCalendar">
            mdi-calendar
          </v-icon>
        </template>

      </v-text-field>
    </template>
    <v-date-picker
      v-model="pickerDate"
      @input="showPicker = false"
      @change="pickerChanged"
      :dense="dense"
      :disabled="disabled"
      :allowed-dates="allowedDates"
    ></v-date-picker>
  </v-menu>
</template>
<script>
import { format, parseISO, parse, isMatch } from 'date-fns'
export default {
  name: 'DatePicker',
  props: {
    date: String,
    label: String,
    placeholder: String,
    defaultToToday: Boolean,
    hideDetails: Boolean,
    classItems: String,
    styleItems: String,
    dateValid: {
      type: [Boolean, String],
      default: true
    },
    dense: String,
    disabled: Boolean
  },
  data: () => ({
    showPicker: false,
    pickerDate: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
    inputDate: format(parseISO(new Date().toISOString()), 'MM/dd/y')
  }),
  methods: {
    pickerChanged () {
      if (this.pickerDate) {
        this.inputDate = format(parseISO(this.pickerDate), 'MM/dd/y')
        this.$emit('date-change', this.pickerDate)
      }
    },
    inputChanged () {
      if (isMatch(this.inputDate, 'MM/dd/y')) {
        this.pickerDate = format(parse(this.inputDate, 'MM/dd/y', new Date()), 'yyyy-MM-dd')
        this.$emit('date-change', this.pickerDate)
      } else {
        this.pickerDate = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
        this.$emit('date-change', '')
      }
    },
    toggleCalendar () {
      // Make sure the datepicker is updated with the date from the text field
      this.inputChanged()
    },
    clearInput () {
      this.inputDate = null
      this.pickerDate = null
    },
    checkDate () {
      if (this.date) {
        if (isMatch(this.date, 'yyyy-MM-dd')) {
          this.inputDate = format(parseISO(this.date), 'MM/dd/y')
        } else {
          this.inputDate = this.date
        }
      }
    },
    lostFocus () {
      this.$emit('blur')
    },
    allowedDates (date) {
      if (new Date(date + 'T12:00:00').getDay() === 0 ||
          new Date(date + 'T12:00:00').getDay() === 6) {
        return false
      }
      return true
    }
  },
  mounted () {
    if (this.defaultToToday === false) {
      this.inputDate = null
    }
    this.checkDate()
  },
  watch: {
    date: function () {
      this.checkDate()
    },
    showPicker: function (newVal, oldVal) {
      if (newVal === 'false') {
        this.$emit('blur')
      }
    }
  }
}
</script>

<style scoped>

</style>
