<template>
  <v-dialog v-model="dialog" max-width="650" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title>Update Referral</v-card-title>
      <v-card-text>
        <ReferralOutForm v-if="referral.id" :passed-referral="referral" @updated="reassignReferral" ref="referralForm" @addNote="addNote"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancel">Cancel</v-btn>
        <v-btn depressed color="primary" @click="updateReferral" :loading="loading">Update</v-btn>
      </v-card-actions>
      <v-divider class="my-2"></v-divider>
      <v-card-text>
        <div class="font-weight-bold">Referral Log History</div>
      </v-card-text>
      <v-card-text>
        <v-timeline
          dense
          clipped
        >
          <v-slide-x-transition
            group
          >
            <v-timeline-item
              v-for="log in logs"
              :key="log.id"
              class="mb-4"
              color="primary"
              small
            >
              <v-row justify="space-between">
                <v-col
                  cols="7"
                  v-text="log.log"
                ></v-col>
                <v-col
                  class="text-right"
                  cols="5"
                >
                  <div>
                    <div>{{log.author.name}}</div>
                    <div>{{ formatCarbonDate(log.updated_at) }}</div>
                  </div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-slide-x-transition>
        </v-timeline>
      </v-card-text>
      </v-card>
  </v-dialog>
</template>
<script>

import RulesMixin from '@/mixins/RulesMixin'
import event, { Events } from '@/event'
import ReferralOutForm from '@/components/Case/Forms/ReferralOutForm.vue'
import structuredClone from '@ungap/structured-clone'
import { formatCarbonDate, formatCarbonDateNoSetTime } from '@/js/PatientIntake/functions'
export default {
  name: 'UpdateReferralOutDialog',
  mixins: [RulesMixin],
  components: { ReferralOutForm },
  data: () => ({
    dialog: false,
    referral: {},
    loading: false,
    logs: [],
    noteLoading: false
  }),
  methods: {
    formatCarbonDate,
    open (referral) {
      this.referral = structuredClone(referral)
      this.formatReferralDates()
      this.formatReferralTime()
      this.getReferralLogHistory()
      this.dialog = true
    },
    cancel () {
      this.dialog = false
      this.reset()
    },
    reset () {
      this.referral = {}
      this.$refs.referralForm.reset()
    },
    reassignReferral (value) {
      this.referral = value
    },
    formatReferralTime () {
      if (!this.referral.appointment_time) {
        this.referral.time = ''
        this.referral.day = ''
        return
      }
      const parts = this.referral.appointment_time.split(' ')
      this.referral.time = parts[0]
      this.referral.day = parts[1]
    },
    async addNote (note) {
      if (!note) {
        this.$refs.referralForm.noteLoading = false
        return
      }
      await window.axios.post(this.$store.getters['Organization/apiUrl'] + '/case/' + this.referral.case_id + '/referrals-out/' + this.referral.id + '/logs', {
        log: note
      })
      this.$refs.referralForm.noteLoading = false
      this.$refs.referralForm.referral.note = ''
      await this.getReferralLogHistory()
      this.$emit('reload')
    },
    async updateReferral () {
      this.loading = true
      this.$refs.referralForm.validate()
      if (!this.$refs.referralForm.formValid || this.$refs.referralForm.dateValid === 'Required' || this.$refs.referralForm.apptDateValid === 'Required') {
        this.loading = false
        return
      }

      try {
        await window.axios.put(this.$store.getters['Organization/apiUrl'] + '/case/' + this.referral.case_id + '/referrals-out/' + this.referral.id, {
          referral: this.referral
        }).then(async () => {
          event.emit(Events.SUCCESS, 'Referral updated successfully', 2000)
          this.$emit('reload')
          this.reset()
          this.dialog = false
        })
      } catch (error) {
        this.loading = false
        if (error.response.data.payload.status === 422) {
          return
        }
        event.emit(Events.ERROR, error.response.data.payload.message, 2000)
      }
      this.loading = false
    },
    async getReferralLogHistory () {
      const response = await window.axios.get(this.$store.getters['Organization/apiUrl'] + '/case/' + this.referral.case_id + '/referrals-out/' + this.referral.id + '/logs')
      this.logs = response.data.payload.logs
    },
    formatReferralDates () {
      this.referral.date_sent = formatCarbonDateNoSetTime(this.referral.date_sent)
      if (this.referral.appointment_date) {
        this.referral.appointment_date = formatCarbonDateNoSetTime(this.referral.appointment_date)
      }
    }
  }
}
</script>
