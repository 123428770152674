<template>
  <v-dialog v-model="dialog" max-width="600" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title class="dialog-banner">Create Telephone Encounter</v-card-title>
      <v-card-text class="mt-6">
        <v-form ref="form" v-model="formValid">
          <v-row dense>
            <v-col>
              <date-picker
                  ref="datepicker"
                  :date="encounter.encounter_date"
                  :default-to-today="false"
                  label="Encounter Date"
                  @date-change="setEncounterDate"
                  :dateValid="dateValid"
                  :rules="[rules.required]"
              />
            </v-col>
            <v-col>
              <v-autocomplete
                  :items="providerOptions"
                  v-model="encounter.provider_option"
                  item-text="provider_name"
                  item-value="id"
                  filled
                  label="Provider"
                  :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-textarea
              v-model="encounter.note"
              label="Telephone Encounter Note"
              placeholder="Please describe the encounter..."
              filled
              :rules="[rules.required]"
          />
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn text @click="cancel">Cancel</v-btn>
        <v-btn depressed color="primary" @click="createEncounter">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import event, { Events } from '@/event'
import { mapGetters } from 'vuex'
import DatePicker from '@/components/PatientIntake/DatePicker.vue'
import RulesMixin from '@/mixins/RulesMixin'
import { ProviderOption } from '@/js/Provider/TelephoneEncounter'
import structuredClone from '@ungap/structured-clone'

export default {
  name: 'CreateTelephoneEncounterDialog',
  components: { DatePicker },
  mixins: [RulesMixin],
  computed: {
    ...mapGetters({
      patient: 'ProviderPatient/patientDemographics',
      providers: 'Organization/providers'
    })
  },
  data: () => ({
    dialog: false,
    encounter: {
      encounter_date: '',
      provider_option: null,
      note: ''
    },
    ProviderOption,
    providerOptions: [],
    formValid: true,
    dateValid: true
  }),
  methods: {
    open () {
      this.dialog = true
      this.getProviders()
    },
    cancel () {
      this.reset()
    },
    reset () {
      this.encounter = {
        encounter_date: '',
        provider_option: null,
        note: ''
      }
      this.$refs.form.reset()
      this.$refs.form.resetValidation()

      this.providerOptions = []
      this.formValid = true
      this.dateValid = true

      this.$nextTick(() => {
        this.$refs.datepicker.clearInput()
        this.$refs.datepicker.checkDate()
      })
      this.dialog = false
    },
    formatProviderOptions () {
      this.providerOptions = [
        { header: 'Multiple Providers (Acknowledgement)' },
        { divider: true },
        ProviderOption.ALL_LOCATION_PROVIDERS,
        ProviderOption.ALL_PATIENT_PROVIDERS,
        { header: 'Individual Providers (Response Required)' },
        { divider: true }
      ]

      // provider drop down wont match an int id - convert to string
      let providers = structuredClone(this.providers)
      providers = providers.map(provider => {
        provider.id = provider.id.toString()
        return provider
      })

      this.providerOptions = this.providerOptions.concat(providers)
    },
    validateDate () {
      this.dateValid = true
      if (!this.encounter.encounter_date) {
        this.dateValid = 'Required'
      }
    },
    setEncounterDate (value) {
      this.encounter.encounter_date = value
    },
    async getProviders () {
      await this.$store.dispatch('Organization/getProviders')
      this.formatProviderOptions()
    },
    async createEncounter () {
      this.$refs.form.validate()
      this.validateDate()

      if (!this.formValid || this.dateValid === 'Required') {
        return
      }

      try {
        await window.axios.post(this.$store.getters['Organization/apiUrl'] + '/provider/patients/' + this.patient.id + '/telephone-encounters', {
          encounter: this.encounter
        })
        this.$emit('encounterChange')
      } catch (error) {
        event.emit(Events.ERROR, error.response.data.payload.message, 2000)
      }

      this.cancel()
    }
  }
}
</script>
<style scoped>
.dialog-banner {
  background-color: #043561;
  color: #ffffff;
}
</style>
